import { OrganisationDTO } from "./organisation.dto";
import { ConferenceDTO } from "./conference.dto";
import { StationDTO } from "./station.dto";
import { UserTypeDTO } from "./user_type.dto";

export class UserDTO {

    id: number;
    username: string;
    password: string;
    first_name?: string;
    last_name?: string;
    phone_number: String;
    email: string;
    active: Boolean;
    station: StationDTO;
    user_type: UserTypeDTO
    user_id: Number;
    user_type_id: Number;
    station_id: Number;
    access_token: String;
    is_board_member: boolean;

}