import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ExpenseCategoryDTO } from '../dtos/expense_category.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class ExpenseCategoriesService {

  public selectedExpenseCategory: ExpenseCategoryDTO = new ExpenseCategoryDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/expenseCategories/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/expenseCategories/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/expenseCategories/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeExpenseCategoryStatus(expense_category_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/expenseCategories/${expense_category_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateExpenseCategory(expense_category: ExpenseCategoryDTO, expense_category_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/expenseCategories/${expense_category_id}`, expense_category)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createExpenseCategory(expense_category: ExpenseCategoryDTO){

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/expenseCategories/`, expense_category)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }
}
