import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IncomeCategoryDTO } from 'src/app/core/dtos/income_category.dto';
import { AppropriationsService } from 'src/app/core/services/appropriations.service';
import { OrganisationsService } from 'src/app/core/services/organisations.service';
import { IncomeCategoriesService } from 'src/app/core/services/income-categories.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-income-category-modal',
  templateUrl: './income-category-modal.component.html',
  styleUrls: ['./income-category-modal.component.scss']
})
export class IncomeCategoryModalComponent implements OnInit {

  entityName: String = 'Income Categories';
  submitted: boolean;
  incomeCategoryForm: FormGroup;
  incomeCategoryDTO: IncomeCategoryDTO = new IncomeCategoryDTO();
  appropriations:any[] = [];
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private incomeCategoryService: IncomeCategoriesService, private appropriationService: AppropriationsService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, private utilsService: UtilsService, public formBuilder: FormBuilder) { 
    this.fetchAppropriations();
  }

  ngOnInit(): void {
    this.incomeCategoryDTO = this.incomeCategoryService.selectedIncomeCategory;
    console.log(this.incomeCategoryDTO);
    this.isEdit = this.incomeCategoryService.isEdit;

    this.incomeCategoryForm = this.formBuilder.group({
      name: [(this.incomeCategoryDTO ? this.incomeCategoryDTO.name : ''), Validators.required],
      appropriation_id: [(this.incomeCategoryDTO ? this.incomeCategoryDTO?.appropriation?.id : ''), Validators.required],
    })

    
    this.incomeCategoryForm.patchValue({ appropriation_id: this.incomeCategoryDTO?.appropriation?.id });
    
  }

  fetchAppropriations() {

    this.ngxService.start();
    this.utilsService.getDataWithoutTable(this.appropriationService.viewActive(), `${this.entityName}`).subscribe(
      data => {
        this.ngxService.stop();
        //set data in service
        this.appropriations = data;
        //patch the value of select
        // this.addFaqForm.patchValue({ categoryId: this.selectCategories[0]?.id });
      },
      error => {
        this.ngxService.stop();
      });

  }



  get f() {
    return this.incomeCategoryForm.controls;
  }

  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.incomeCategoryForm.invalid) {
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): IncomeCategoryDTO {
    var requestDTO = new IncomeCategoryDTO();
    requestDTO.name = this.f.name.value;
    requestDTO.income_category_id = this.incomeCategoryDTO.id;
    requestDTO.appropriation_id = this.incomeCategoryDTO.appropriation.id;
    requestDTO.active = true;
    return requestDTO;
  }

}
