import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, public toastr: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                const error1 = err.error.detail.message;
                this.authenticationService.logout();
                location.reload();
                return throwError(error1);
            }
            if (err.status === 403) {
                // auto logout if 401 response returned from api
                const error1 = err.error.detail.message;
                this.toastr.error(error1, 'Forbidden');
                return throwError(error1);
            }
            if (err.status === 404) {
                console.log(err.error);
                const error1 = err.error.detail.message;
                // this.ngxService.stopAll();
                // this.toastr.error(err.error.detail.message, 'Not Found');
                return throwError(error1);
            }
            if (err.status === 400) {
                console.log(err.error);
                const error1 = err.error.detail.message;
                // this.ngxService.stopAll();
                // this.toastr.error(err.error.detail.message, 'Not Found');
                return throwError(error1);
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
