import { OrganisationDTO } from "./organisation.dto";
import { PaymentMethodDTO } from "./payment_method.dto";

export class AccountDTO {
    id: Number;
    account_name: String;
    account_number: String;
    branch: String;
    payment_method: PaymentMethodDTO;
    payment_method_id: Number;
    organisation_id: Number;
    organisation: OrganisationDTO;
    active: Boolean;
    account_id: Number;
}