import { AppropriationDTO } from "./appropriation.dto";
import { CurrencyDTO } from "./currency.dto";

export class GoalDTO {
    id: Number;
    name: String;
    amount: number;
    appropriation_id: Number;
    appropriation: AppropriationDTO;
    currency_id: number;
    currency: CurrencyDTO;
    status: Boolean;
    start_date: String;
    end_date: String;
    church_id: Number;
    expense_category_id: Number;
    active: Boolean;
    goal_id: Number;
}