import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserTypeDTO } from 'src/app/core/dtos/user_type.dto';
import { UserTypesService } from 'src/app/core/services/user-types.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-user-type-modal',
  templateUrl: './user-type-modal.component.html',
  styleUrls: ['./user-type-modal.component.scss']
})
export class UserTypeModalComponent implements OnInit {

  entityName: String = 'User Types';
  submitted: boolean;
  userTypeForm: FormGroup;
  userTypeDTO: UserTypeDTO = new UserTypeDTO();
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private userTypeService: UserTypesService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, private utilsService: UtilsService, public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.userTypeDTO = this.userTypeService.selectedUserType;
    this.isEdit = this.userTypeService.isEdit;

    this.userTypeForm = this.formBuilder.group({
      name: [(this.userTypeDTO ? this.userTypeDTO.name : ''), Validators.required],
    })
    
  }


  get f() {
    return this.userTypeForm.controls;
  }

  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userTypeForm.invalid) {
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): UserTypeDTO {
    var requestDTO = new UserTypeDTO();
    requestDTO.name = this.f.name.value;
    requestDTO.user_type_id = this.userTypeDTO.id;
    requestDTO.active = true;
    return requestDTO;
  }

}
