import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GoalDTO } from '../dtos/goal.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class GoalsService {

  public selectedGoal: GoalDTO = new GoalDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/goals/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/goals/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/goals/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeGoalStatus(goal_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/goals/${goal_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateGoal(goal: GoalDTO, goal_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/goals/${goal_id}`, goal)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  viewGoalItems() {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/goals/goal/items/`)
    .pipe(map(data => {
      // request successful log response
      console.log(data);
      return data;
    }));
  }

  createGoal(goal: GoalDTO){

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/goals/`, goal)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }
}
