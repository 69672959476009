import { Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { IAngularMyDpOptions, IMyDate, IMyDateModel } from 'angular-mydatepicker';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToasterType } from '../models/toaster.type';
import { AuthenticationService } from '../services/auth.service';
import { AdvancedTableService } from './advanced-table.service';
// import { Howl } from 'howler';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  public currentLoaderTextSubject: BehaviorSubject<String>;
  private today = new Date();
  // public notificationSound: Howl;
  todayDate: IMyDate = { year: new Date().getFullYear(), month: new Date().getMonth(), day: new Date().getDay() };
  constructor(public service: AdvancedTableService, private ngxService: NgxUiLoaderService, private router: Router, private toastr: ToastrService, private auth: AuthenticationService) {
    this.currentLoaderTextSubject = new BehaviorSubject<String>('');
    // this.notificationSound = new Howl({
    //   src: ['assets/audios/notification.mp3']
    // });

  }
  myOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mmm-yyyy',
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
    // other options...
  };

  myFututeOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mmm-yyyy',
    disableUntil: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1 },
    disableSince: { year: 0, month: 0, day: 0 }
    // other options...
  };

  disableDatePickerUntil(date: Date): IAngularMyDpOptions {
    let d: Date = date;
    d.setDate(d.getDate() - 1);
    let copy: IAngularMyDpOptions = this.getCopyOfOptions();
    copy.disableUntil = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    return copy;
  }



  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.myOptions));
  }
  public showSuccessDialog(title: string, message: string,) {
    Swal.fire({
      title: title,
      text: message,
      icon: 'success',
      confirmButtonColor: '#28367C'
    });
  }

  public showSuccessDialogWithRouting(title: string, message: string, reRoute: boolean, route: string) {
    Swal.fire({
      title: title,
      text: message,
      icon: 'success',
      confirmButtonColor: '#28367C'
    }).then(result => {
      if (result.value) {
        if (reRoute) {
          this.router.navigate([route]);
        }

      }
    });
  }

  public confirmDialogWithAction(title: string, message: string, confirmText: string, successTitle: string, successMessage: string, method) {

    Swal.fire({
      title: title,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#ff3d60',
      confirmButtonColor: '#28367C',
      confirmButtonText: confirmText,
    }).then(result => {
      if (result.value) {
        method
        this.showSuccessDialog(successTitle, successMessage)
      }
    });

  }

  showToaster(title: string, message: string, toasterType: ToasterType) {
    switch (toasterType) {
      case ToasterType.ERROR: this.toastr.error(message, title);
        break;
      case ToasterType.WARNING: this.toastr.warning(message, title);
        break;
      case ToasterType.SUCCESS: this.toastr.success(message, title);
        break;
      case ToasterType.INFO: this.toastr.info(message, title);
        break;
      default: this.toastr.info(message, title);
        break;
    }

  }
  getData(serviceMethod, loaderText: string) {
    this.currentLoaderTextSubject.next(loaderText)
    this.ngxService.start();
    serviceMethod
      .pipe(first())
      .subscribe(
        data => {
          this.ngxService.stop();
          //set data in service
          this.service.setTables$(data.items);
          this.service.setTotal$(data.total);
          this.service.setPage(data.page);
          this.service.setPageSize(data.size);

        },
        error => {
          const items = []
          this.service.setTables$(items);
          this.service.setTotal$(0);
          this.service.setPage(1);
          this.service.setPageSize(0);
          this.ngxService.stop();
        });
  }

  getDataWithoutPagination(serviceMethod, loaderText: string) {

    this.currentLoaderTextSubject.next(loaderText)
    this.ngxService.start();
    serviceMethod
      .pipe(first())
      .subscribe(
        data => {
          this.ngxService.stop();
          //set data in service
          this.service.setTables$(data);
          this.service.setTotal$(data.length);
          this.service.setPage(1);
          this.service.setPageSize(100);

        },
        error => {
          this.ngxService.stop();
        });

  }

  getDataWithoutTable(serviceMethod, loaderText: string): any {
    var fetchedData = [];
    this.currentLoaderTextSubject.next(loaderText)
    this.ngxService.start();
    return serviceMethod.pipe(first());
  }

  customeGetDataWithoutTable(serviceMethod, loaderText: string): any {
    var fetchedData = [];
    // this.currentLoaderTextSubject.next(loaderText)
    // this.ngxService.start();
    return serviceMethod.pipe(first());
  }


  pad(n) {
    return (n < 10) ? ('0' + n) : n;
  }
  getDateFromDatepicker(datePickerValue) {
    return datePickerValue.singleDate.jsDate.getFullYear() + '-' + this.pad(datePickerValue.singleDate.jsDate.getMonth() + 1) + '-' + this.pad(datePickerValue.singleDate.jsDate.getDate());
  }

  getUpdateDateFromDatePicker(datePickerValue) {
    return datePickerValue.singleDate.date.year + '-' + this.pad(datePickerValue.singleDate.date.month) + '-' + this.pad(datePickerValue.singleDate.date.day);
  }

  getPhonenumberAsString(intelInputValue): String {
    if (intelInputValue) {
      return intelInputValue.e164Number.replace('+', '');
    } else {
      return '';
    }

  }

  getTimeFromTimePicker(timePickerValue) {
    return this.pad(timePickerValue.getHours()) + ':' + this.pad(timePickerValue.getMinutes());
  }

  getTimeFromTimePickerWithSeconds(timePickerValue) {
    console.log("Getting Seconds");
    console.log(timePickerValue.getSeconds());
    return this.pad(timePickerValue.getHours()) + ':' + this.pad(timePickerValue.getMinutes()) + ':' + this.pad(timePickerValue.getSeconds());
  }

  getRangeDateFromDatepicker(datePickerValue) {
    return datePickerValue.jsDate.getFullYear() + '-' + this.pad(datePickerValue.jsDate.getMonth() + 1) + '-' + this.pad(datePickerValue.jsDate.getDate());
  }

  downloadDocument(serviceMethod, documentName: string) {
    this.currentLoaderTextSubject.next('Downloading File...')
    this.ngxService.start();
    serviceMethod
      .pipe(first())
      .subscribe((response: any) => {

        const dataType = response.type;

        //get path here and open anoth
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

        downloadLink.setAttribute('download', documentName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.ngxService.stop();
        // this.toastr.success( 'File Downloading Now', 'Success!!');

        setTimeout(() => {
          this.ngxService.stop();
        }, 1000);

      }, error => {

        this.toastr.error("Failed to download file. Please try again later.");

        setTimeout(() => {
          this.ngxService.stop();
        }, 1000);

      })

  }

  hasRole(role: any[]) {
    if (role) {
      var i = 0;
      const roles = JSON.parse(localStorage.getItem('currentUser')).userRoles;
      var intersections = roles.filter(e => role.indexOf(e.authority) !== -1);
      if (intersections.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  hasPriviledge(priviledge: any[], permission: any[]) {
    // console.log("Privilege being checked")
    // console.log(priviledge);
    // console.log("Permission being checked")
    // console.log(permission);
    if (permission) {
      var i = 0;
      this.auth.getLocalCurrentUser();
      const roles = this.auth.roles;
      // const roles = JSON.parse(localStorage.getItem('LoggedInUser')).permissions;
      var intersections = roles.filter(e => permission.indexOf(e.name) !== -1);
      // console.log("Shwoing Intersections found");
      // console.log(intersections);
      var priviledgeIntersections = [];
      // console.log(permission);
      if (intersections.length > 0) {
        intersections.forEach(element => {
          // console.log(element.name)
          priviledgeIntersections.push(element.priviledges.filter(e => priviledge.indexOf(e.name) !== -1));
          // console.log(priviledgeIntersections)
        });
        if (priviledgeIntersections.length > 0) {
          return true;
        } else {
          return false;

        }

      } else {
        return false;
      }

    } else {
      return true;
    }
  }


  hasPermission(permission: any[]) {
    if (permission) {
      var i = 0;
      this.auth.getLocalCurrentUser();
      const roles = this.auth.roles;
      // 
      var intersections = roles.filter(e => permission.indexOf(e.name) !== -1);
      if (intersections.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

}
