import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ArticleDTO } from "src/app/core/dtos/article.dto";
import { NewsService } from "src/app/core/services/news.service";
import { UtilsService } from "src/app/core/utils/utils.service";

@Component({
  selector: "app-news-modal",
  templateUrl: "./news-modal.component.html",
  styleUrls: ["./news-modal.component.scss"],
})
export class NewsModalComponent implements OnInit {
  entityName: String = "Articles";
  submitted: boolean;
  newsForm: FormGroup;
  articleDTO: ArticleDTO = new ArticleDTO();
  isEdit = false;
  error = "";
  public Editor = ClassicEditor;
  public config = {
    placeholder: "Type the content here!",
  };

  imageChangedEvent: any = "";
  croppedImage: any = "";
  imageToSend: any;
  fileName: any = "";
  defaultAlreadyExists: boolean;
  showCropper = false;

  constructor(
    private activeModal: NgbActiveModal,
    private newsService: NewsService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private utilsService: UtilsService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.articleDTO = this.newsService.selectedArticle;
    this.isEdit = this.newsService.isEdit;

    this.newsForm = this.formBuilder.group({
      title: [
        this.articleDTO ? this.articleDTO.title : "",
        Validators.required,
      ],
      description: [
        this.articleDTO ? this.articleDTO.description : "",
        Validators.required,
      ],
      image: [""],
    });
  }

  get f() {
    return this.newsForm.controls;
  }

  close() {
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newsForm.invalid) {
      return;
    } else {
      //add to list
      //Pass value
      console.log("Passing Value from modal");
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): ArticleDTO {
    var requestDTO = new ArticleDTO();
    requestDTO.title = this.f.title.value;
    requestDTO.description = this.f.description.value;
    requestDTO.active = true;
    requestDTO.image = this.dataURLtoFile(this.croppedImage, this.fileName);
    return requestDTO;
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  fileChangeEvent(event: any): void {
    // this.imageChangedEvent = event;

    if (event.target.files && event.target.files[0]) {
      this.fileName = event.target.files[0].name;

      if (
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpg"
      ) {
        if (event.target.files[0].size > 10000000) {
          // checking size here - 2MB}
          console.log("Image Too Big");
          this.f["image"].setValue(null);
          this.imageChangedEvent = null;
          this.croppedImage = null;
          this.toastr.error("Image is too big.");
          return;
        }
        this.imageChangedEvent = event;
      } else {
        console.log("type is not image");
        this.f["image"].setValue(null);
        this.imageChangedEvent = null;
        this.croppedImage = null;
        this.toastr.error("File selected is not an image");
      }
    } else {
      console.log("Nothing selected");
      // this.toastr.error('Nothing selected');
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imageToSend = event;
    console.log(event);
    // let file:File = event.target.files[0];
  }

  imageLoaded() {
    // show cropper
    // this.showCropper = true;
    console.log("Image loaded");
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
