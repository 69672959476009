import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserTypeDTO } from '../dtos/user_type.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class UserTypesService {

  public selectedUserType: UserTypeDTO = new UserTypeDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/userTypes/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/userTypes/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/userTypes/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeUserTypeStatus(user_type_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/userTypes/${user_type_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateUserType(user_type: UserTypeDTO, user_type_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/userTypes/${user_type_id}`, user_type)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createUserType(user_type: UserTypeDTO){

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/userTypes/`, user_type)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

}
