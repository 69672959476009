import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { StationDTO } from 'src/app/core/dtos/station.dto';
import { CountriesService } from 'src/app/core/services/countries.service';
import { StationsService } from 'src/app/core/services/stations.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-station-modal',
  templateUrl: './station-modal.component.html',
  styleUrls: ['./station-modal.component.scss']
})
export class StationModalComponent implements OnInit {

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Zimbabwe, CountryISO.SouthAfrica];


  entityName: String = 'Stations';
  submitted: boolean;
  stationForm: FormGroup;
  stationDTO: StationDTO = new StationDTO();
  countries:any[] = [];
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private stationService: StationsService, private countryService: CountriesService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, private utilsService: UtilsService, public formBuilder: FormBuilder) { 
    this.fetchCountries();
  }

  ngOnInit(): void {
    this.stationDTO = this.stationService.selectedStation;
    console.log(this.stationDTO);
    this.isEdit = this.stationService.isEdit;

    if (this.isEdit) {

      this.stationForm = this.formBuilder.group({
        name: [(this.stationDTO ? this.stationDTO.name : ''), Validators.required],
        address: [(this.stationDTO ? this.stationDTO.address : ''), Validators.required],
        country_id: [(this.stationDTO ? this.stationDTO?.country?.id : 0), Validators.required]
      })

      this.stationForm.patchValue({ country_id: this.stationDTO?.country?.id });

    } else {
      this.stationForm = this.formBuilder.group({
        name: [(this.stationDTO ? this.stationDTO.name : ''), Validators.required],
        address: [(this.stationDTO ? this.stationDTO.address : ''), Validators.required],
        country_id: [(this.stationDTO ? this.stationDTO?.country?.id : 0), Validators.required],
        contacts: this.formBuilder.array([]),
      })

      this.stationForm.patchValue({ country_id: this.stationDTO?.country?.id });

      this.addContact()
    }


  }

  fetchCountries() {

    this.ngxService.start();
    this.utilsService.getDataWithoutTable(this.countryService.viewActive(), `Fetching Countries`).subscribe(
      data => {
        this.ngxService.stop();
        //set data in service
        this.countries = data;
        //patch the value of select
        // this.addFaqForm.patchValue({ categoryId: this.selectCategories[0]?.id });
      },
      error => {
        this.ngxService.stop();
      });

  }

  get f() {
    return this.stationForm.controls;
  }

  contacts(): FormArray {
    return this.stationForm.get('contacts') as FormArray
  }

  newContact(): FormGroup {
    return this.formBuilder.group({
      label: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      primary_phone: ['', Validators.required],
      primary_email: ['', Validators.required],
    })
  }

  addContact() {
    this.contacts().push(this.newContact())
  }

  removeContact(i: number) {
    this.contacts().removeAt(i);
  }


  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.stationForm.invalid) {
      console.log(this.stationForm)
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): StationDTO {
    var requestDTO = new StationDTO();
    requestDTO.name = this.f.name.value;
    requestDTO.address = this.f.address.value;
    requestDTO.country_id = this.f.country_id.value;
    requestDTO.station_id = this.stationDTO.id;
    requestDTO.active = true;
    requestDTO.contacts = typeof this.f.contacts == 'undefined' ? '' : this.sanitizeContacts(this.f.contacts.value);
    return requestDTO;
  }

  sanitizeContacts(contacts) {
    contacts.forEach(contact => {
      contact.primary_phone = this.utilsService.getPhonenumberAsString(contact.primary_phone);
    });

    return contacts;
  }

}
