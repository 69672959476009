import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppropriationDTO } from 'src/app/core/dtos/appropriation.dto';
import { AppropriationsService } from 'src/app/core/services/appropriations.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-appropriation-modal',
  templateUrl: './appropriation-modal.component.html',
  styleUrls: ['./appropriation-modal.component.scss']
})
export class AppropriationModalComponent implements OnInit {

  entityName: String = 'Appropriations';
  submitted: boolean;
  appropriationForm: FormGroup;
  appropriationDTO: AppropriationDTO = new AppropriationDTO();
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private appropriationService: AppropriationsService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, private utilsService: UtilsService, public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.appropriationDTO = this.appropriationService.selectedAppropriation;
    this.isEdit = this.appropriationService.isEdit;

    this.appropriationForm = this.formBuilder.group({
      name: [(this.appropriationDTO ? this.appropriationDTO.name : ''), Validators.required],
    })
    
  }


  get f() {
    return this.appropriationForm.controls;
  }

  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.appropriationForm.invalid) {
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): AppropriationDTO {
    var requestDTO = new AppropriationDTO();
    requestDTO.name = this.f.name.value;
    requestDTO.appropriation_id = this.appropriationDTO.id;
    requestDTO.active = true;
    return requestDTO;
  }


}
