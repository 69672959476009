<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="newsForm">
  <div class="modal-header">
    <h4 class="modal-title">
      <ng-container *ngIf="isEdit">Update </ng-container>
      <ng-container *ngIf="!isEdit">New </ng-container>
      Article
    </h4>
    <a class="close" aria-label="Close" (click)="close()">
      <i class="mdi mdi-close noti-icon"></i>
    </a>
  </div>
  <div class="modal-body">
    <div class="row mb-4">
      <div class="col-6">
        <div class="form-group">
          <label for="validationCustom02">Title</label>
          <input
            type="text"
            class="form-control"
            id="validationCustom02"
            formControlName="title"
            placeholder="Enter Title"
            [ngClass]="{
              'is-invalid': submitted && f.title.errors,
              'is-valid': submitted && !f.title.errors
            }"
          />
          <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
            <span *ngIf="f.title.errors?.required"
              >Please provide a valid title.</span
            >
          </div>
          <div *ngIf="submitted && !f.title.errors" class="valid-feedback">
            <span *ngIf="!f.title.errors">Looks good!</span>
          </div>
        </div>
      </div>

      <div [ngClass]="isEdit ? 'col-12' : 'col-6'">
        <div class="form-group" *ngIf="!isEdit">
          <label for="validationCustom02">Featured Image</label>
          <input
            type="file"
            accept="image/*"
            class="form-control"
            formControlName="image"
            (change)="fileChangeEvent($event)"
            id="validationCustom02"
          />
          <div *ngIf="submitted && f.image.errors" class="invalid-feedback">
            <span *ngIf="f.image.errors?.required"
              >Please provide a valid image.</span
            >
          </div>
          <div *ngIf="submitted && !f.image.errors" class="valid-feedback">
            <span *ngIf="!f.image.errors">Looks good!</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div [ngClass]="isEdit ? 'col-12' : 'col-6'">
        <div class="form-group">
          <label for="validationCustom02">Description</label>
          <ckeditor
            #editor
            [editor]="Editor"
            [data]="f.description.value"
            formControlName="description"
          ></ckeditor>
          <div
            *ngIf="submitted && f.description.errors"
            class="invalid-feedback"
          >
            <span *ngIf="f.description.errors?.required"
              >Please provide a valid description.</span
            >
          </div>
          <div
            *ngIf="submitted && !f.description.errors"
            class="valid-feedback"
          >
            <span *ngIf="!f.description.errors">Looks good!</span>
          </div>
        </div>
      </div>

      <div class="col-6" *ngIf="!isEdit">
        <div class="row">
          <div class="col-6" style="max-width: 400px">
            <image-cropper
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="true"
              [aspectRatio]="4 / 3"
              format="png"
              (imageCropped)="imageCropped($event)"
              (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady()"
              (loadImageFailed)="loadImageFailed()"
            >
            </image-cropper>
          </div>

          <div class="col-6">
            <img [src]="croppedImage" style="width: 200px" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="submit">
      <ng-container *ngIf="isEdit">Update </ng-container>
      <ng-container *ngIf="!isEdit">Save </ng-container>
      Article
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      Close
    </button>
  </div>
</form>
