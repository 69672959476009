import { AppropriationDTO } from "./appropriation.dto";

export class IncomeCategoryDTO {

    id: Number;
    name: String;
    church_id: Number;
    income_category_id: Number;
    active: Boolean;
    appropriation: AppropriationDTO;
    appropriation_id: number;

}