import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 201,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: '/dashboard',
    },
    {
        id: 204,
        label: 'Countries',
        icon: 'bx-globe',
        link: '/countries/view',
    },
    {
        id: 205,
        label: 'Stations',
        icon: 'bx-analyse',
        link: '/stations/view',
        
    },
    {
        id: 206,
        label: 'News',
        icon: 'bx-news',
        link: '/news/view',
    },
    {
        id: 207,
        label: 'Donations',
        icon: 'bx-credit-card-alt',
        link: '/donations/view',
    },
    {
        id: 208,
        label: 'Organisations',
        icon: 'bx-clinic',
        link: '/organisations/view',
    },
    {
        id: 209,
        label: 'Kids',
        icon: 'bx-user',
        link: '/kids/view',
    },
    {
        id: 212,
        label: 'Contacts',
        icon: 'bxs-contact',
        link: '/contacts/view'
    },
    {
        id: 216,
        label: 'Users',
        icon: 'bx-user-pin',
        subItems: [
            {
                id: 217,
                label: 'Roles',
                link: '/users/user-types',
                parentId: 216
            },
            {
                id: 218,
                label: 'Users',
                link: '/users/view',
                parentId: 216
            },
        ]
    },
    {
        id: 219,
        label: 'Reports',
        icon: 'bx-pie-chart-alt-2',
        subItems: [
            {
                id: 220,
                label: 'Kids',
                link: '/reports/kids',
                parentId: 219
            },
            {
                id: 221,
                label: 'Users',
                link: '/reports/users',
                parentId: 219,
            },
            {
                id: 222,
                label: 'Logins',
                link: '/reports/logins',
                parentId: 219,
            },
            {
                id: 223,
                label: 'Activity',
                link: '/reports/activity',
                parentId: 219,
            }
        ]
    }
];

