import { CountryDTO } from "./country.dto";

export class CityDTO {

    id: Number;
    name: String;
    country_id: Number;
    city_id: Number;
    country: CountryDTO;
    active: Boolean;

}