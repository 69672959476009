import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../models/auth.models';
import { UserDTO } from '../dtos/user.dto';
import { AppSettings } from '../helpers/app-settings';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserProfileService {

  public selectedUser: UserDTO = new UserDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<User[]>(`/api/login`);
  }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/users/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive() {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/users/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/users/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeUserStatus(user_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/users/${user_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateUser(user: UserDTO, user_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/users/${user_id}`, user)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createUser(user: UserDTO) {

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/users/`, user)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  getCurrentUser() {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/users/me/')
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  setCurrentUser(currentUser) {
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    return true
  }

  updateProfile(requestDTO: UserDTO) {

    return this.http.post<any>(AppSettings.API_ENDPOINT + '/users/profile/update', requestDTO)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  changePassword(new_password: string, old_password: string) {

    return this.http.post<any>(AppSettings.API_ENDPOINT + '/users/password/change', {
      'new_password': new_password,
      'old_password': old_password,
    })
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }

  searchUsersWithObservable(query): Observable<any[]> {
    return this.http.post<any>(AppSettings.API_ENDPOINT + `/users/searchByName?query=${query}&page=1&size=50`, query)
      .pipe(map(data => data.items));
    // return of(result).pipe(delay(500));;
  }

}
