import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IncomeDTO } from '../dtos/income.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class IncomesService {

  public selectedIncome: IncomeDTO = new IncomeDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/incomes/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewByChurch(page: Number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/incomes/byChurch/?page=${page}&size=${size}`)
    .pipe(map(data => {
      // request successful log response
      console.log(data);
      return data;
    }));
  }

  viewActive(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/incomes/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewIncomeItems(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/incomes/income/items/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/incomes/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeIncomeStatus(income_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/incomes/${income_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateIncome(income: IncomeDTO, income_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/incomes/${income_id}`, income)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createIncome(income: IncomeDTO){

    console.log(income);
    return this.http.post<any>(AppSettings.API_ENDPOINT + `/incomes/`, income)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  confirmIncome(details: any, income_id: number) {
    console.log(details)
    return this.http.put<any>(AppSettings.API_ENDPOINT + `/incomes/status/${income_id}/`, details)
    .pipe(map(data => {
      // request successful log response
      console.log(data);
      return data;
    }));

  }
}
