import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ContactDTO } from '../dtos/contact.dto';
import { KidDTO } from '../dtos/kid.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class KidService {

  public selectedKid: KidDTO = new KidDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/kids/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewByChurch(page: Number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/kids/byChurch/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive() {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/kids/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/kids/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeKidStatus(status, kid_id) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/kids/${kid_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updatekidImage(kid_id, file) {

    var formdata = new FormData();
    formdata.append('file', file)
    return this.http.put<any>(AppSettings.API_ENDPOINT + `/kids/image/${kid_id}`, formdata)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateKid(kid: KidDTO, kid_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/kids/${kid_id}`, kid)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }

  updateContact(contact: ContactDTO, contact_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/kids/${contact_id}`, contact)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createKid(kid: KidDTO, file) {

    let url = this.serializeUrl(kid)
    var formdata = new FormData();
    formdata.append('file', file)
    return this.http.post<any>(AppSettings.API_ENDPOINT + `/kids/?${url}`, formdata)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  newContact(kid_id, contact: ContactDTO) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + `/kids/contact/new/${kid_id}`, contact)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  confirmKid(details: any, kid_id: number) {
    console.log(details)
    return this.http.put<any>(AppSettings.API_ENDPOINT + `/kids/status/${kid_id}/`, details)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  serializeUrl(obj) {
    var str = "";
    for (var key in obj) {
      if (key != 'file') {
        if (str != "") {
          str += "&";
        }
      }
      str += key + "=" + encodeURIComponent(obj[key]);
    }

    return str;
  }
}


