<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="updatePasswordForm">
    <div class="modal-header">
        <h4 class="modal-title">Update Password
        </h4>
        <a class="close" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close noti-icon"></i>
        </a>
    </div>
    <div class="modal-body">
        <div class="form-group mb-2">
            <label for="old_password">Old Password</label>
            <input type="password" class="form-control" id="old_password" formControlName="old_password" placeholder="Old Password" [ngClass]="{'is-invalid': submitted && f.old_password.errors,'is-valid': submitted && !f.old_password.errors}">
            <div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
                <span *ngIf="f.old_password.errors?.required">Please provide a valid Password</span>
            </div>
            <div *ngIf="submitted && !f.old_password.errors" class="valid-feedback">
                <span *ngIf="!f.old_password.errors">Looks good!</span>
            </div>
        </div>
        <div class="form-group mb-2">
            <label for="password">New Password</label>
            <input type="password" class="form-control" id="password" formControlName="password" placeholder="New Password" [ngClass]="{'is-invalid': submitted && f.password.errors,'is-valid': submitted && !f.password.errors}">
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <span *ngIf="f.password.errors?.required">Please provide a valid new Password</span>
            </div>
            <div *ngIf="submitted && !f.password.errors" class="valid-feedback">
                <span *ngIf="!f.password.errors">Looks good!</span>
            </div>
        </div>
        <div class="form-group mb-2">
            <label for="confirmPassword">Confirm New Password</label>
            <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword" placeholder="Confirm New Password" [ngClass]="{'is-invalid': submitted && f.confirmPassword.errors,'is-valid': submitted && !f.confirmPassword.errors}">
            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                <span *ngIf="f.confirmPassword.errors?.required">Please provide a valid confirm password</span>
                <span *ngIf="f.confirmPassword.errors.MatchPassword">Passwords do not match</span>
            </div>
            <div *ngIf="submitted && !f.confirmPassword.errors" class="valid-feedback">
                <span *ngIf="!f.confirmPassword.errors">Looks good!</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">Update Password
        </button>
        <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
    </div>
</form>