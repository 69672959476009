<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="userForm">
    <div class="modal-header">
        <h4 class="modal-title">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">New </ng-container>
            User
        </h4>
        <a class="close" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close noti-icon"></i>
        </a>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="form-group mb-2">
                <label for="validationCustom02">User Name</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="username" placeholder="Enter Username" [ngClass]="{'is-invalid': submitted && f.username.errors,'is-valid': submitted && !f.username.errors}">
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <span *ngIf="f.username.errors?.required">Please provide a valid username.</span>
                </div>
                <div *ngIf="submitted && !f.username.errors" class="valid-feedback">
                    <span *ngIf="!f.username.errors">Looks good!</span>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group mb-2">
                    <label for="validationCustom02">First Name</label>
                    <input type="text" class="form-control" id="validationCustom02" formControlName="first_name" placeholder="Enter First Name" [ngClass]="{'is-invalid': submitted && f.first_name.errors,'is-valid': submitted && !f.first_name.errors}">
                    <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                        <span *ngIf="f.first_name.errors?.required">Please provide a valid first name.</span>
                    </div>
                    <div *ngIf="submitted && !f.first_name.errors" class="valid-feedback">
                        <span *ngIf="!f.first_name.errors">Looks good!</span>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group mb-2">
                    <label for="validationCustom02">Last Name</label>
                    <input type="text" class="form-control" id="validationCustom02" formControlName="last_name" placeholder="Enter Last Name" [ngClass]="{'is-invalid': submitted && f.last_name.errors,'is-valid': submitted && !f.last_name.errors}">
                    <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                        <span *ngIf="f.last_name.errors?.required">Please provide a valid last name.</span>
                    </div>
                    <div *ngIf="submitted && !f.last_name.errors" class="valid-feedback">
                        <span *ngIf="!f.last_name.errors">Looks good!</span>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group mb-2">
                    <label for="validationCustom02">Email</label>
                    <input type="email" class="form-control" id="validationCustom02" formControlName="email" placeholder="Enter Email" [ngClass]="{'is-invalid': submitted && f.email.errors,'is-valid': submitted && !f.email.errors}">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <span *ngIf="f.email.errors?.required">Please provide a valid email.</span>
                    </div>
                    <div *ngIf="submitted && !f.email.errors" class="valid-feedback">
                        <span *ngIf="!f.email.errors">Looks good!</span>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group mb-2">
                    <label for="validationCustom02">Phone Number</label>

                    <ngx-intl-tel-input [cssClass]="'form-control'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Zimbabwe" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="true" [ngClass]="{'is-invalid': submitted && f.phone_number.errors,'is-valid': submitted && !f.phone_number.errors}"
                        [numberFormat]="PhoneNumberFormat.National" name="phone_number" formControlName="phone_number">
                    </ngx-intl-tel-input>
                    <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                        <span *ngIf="f.phone_number.errors?.required">Please provide a valid phone number.</span>

                        <span *ngIf="f.phone_number.errors?.validatePhoneNumber">Please provide a valid
                            phone
                            number.</span>
                    </div>
                    <div *ngIf="submitted && !f.phone_number.errors" class="valid-feedback">
                        <span *ngIf="!f.phone_number.errors">Looks good!</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group mb-2">
                <label class="control-label">User Type</label>
                <ng-select formControlName="user_type_id" [items]="user_types" bindLabel="name" placeholder="Select User Type" bindValue="id" [ngClass]="{'is-invalid': submitted && f.user_type_id.errors,'is-valid': submitted && !f.user_type_id.errors}">
                </ng-select>
                <div *ngIf="submitted && f.user_type_id.errors" class="invalid-feedback">
                    <div *ngIf="f.user_type_id.errors.required">This value is required</div>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">Save </ng-container>
            User
        </button>
        <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
    </div>
</form>