import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CurrencyDTO } from '../dtos/currency.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {

  public selectedCurrency: CurrencyDTO = new CurrencyDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/currencies/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/currencies/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/currencies/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeCurrencyStatus(currency_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/currencies/${currency_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateCurrency(currency: CurrencyDTO, currency_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/currencies/${currency_id}`, currency)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createCurrency(currency: CurrencyDTO){

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/currencies/`, currency)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }
}
