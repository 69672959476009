<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="articleForm">
    <div class="modal-header">
        <h4 class="modal-title">
            Update Image
        </h4>
        <a class="close" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close noti-icon"></i>
        </a>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-5">
                <div class="form-group col-12 mb-2">
                    <label for="validationCustom02">Current Image</label>
                    <div class="product-img">
                        <img [src]="articlePicUrl" alt="" class="img-fluid mx-auto d-block" data-zoom="assets/images/placeholder-image.png">
                    </div>
                </div>
            </div>
            <div class="col-1">

            </div>
            <div class="col-6">
                <div class="form-group mb-2">
                    <div class="form-group">
                        <label for="validationCustom02">New Image</label>
                        <input type="file" accept="image/*" class="form-control" formControlName="image" (change)="fileChangeEvent($event)" id="validationCustom02">
                        <div *ngIf="submitted && f.image.errors" class="invalid-feedback">
                            <span *ngIf="f.image.errors?.required">Please provide a valid image.</span>
                        </div>
                        <div *ngIf="submitted && !f.image.errors" class="valid-feedback">
                            <span *ngIf="!f.image.errors">Looks good!</span>
                        </div>
                    </div>

                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                    </image-cropper>

                    <img [src]="croppedImage" style="width: 200px;" />
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">Save </ng-container>
            News Image
        </button>
        <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
    </div>
</form>