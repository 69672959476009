import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CountryDTO } from '../dtos/country.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  public selectedCountry: CountryDTO = new CountryDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/countries/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/countries/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/countries/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeCountryStatus(country_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/countries/${country_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateCountry(country: CountryDTO, country_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/countries/${country_id}`, country)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createCountry(country: CountryDTO){

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/countries/`, country)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }
}
