import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SimplebarAngularModule } from 'simplebar-angular';
import { NgbAlertModule, NgbDropdownModule, NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';

import { UIModule } from '../shared/ui/ui.module';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { RightsidebarComponent } from './rightsidebar/rightsidebar.component';
import { HorizontalComponent } from './horizontal/horizontal.component';
import { VerticalComponent } from './vertical/vertical.component';
import { HorizontaltopbarComponent } from './horizontaltopbar/horizontaltopbar.component';
import { LanguageService } from '../core/services/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { CountryModalComponent } from './modals/country-modal/country-modal.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConferenceModalComponent } from './modals/conference-modal/conference-modal.component';
import { CityModalComponent } from './modals/city-modal/city-modal.component';
import { OrganisationModalComponent } from './modals/organisation-modal/organisation-modal.component';
import { ExpenseCategoryModalComponent } from './modals/expense-category-modal/expense-category-modal.component';
import { IncomeCategoryModalComponent } from './modals/income-category-modal/income-category-modal.component';
import { CurrencyModalComponent } from './modals/currency-modal/currency-modal.component';
import { PaymentMethodModalComponent } from './modals/payment-method-modal/payment-method-modal.component';
import { UserTypeModalComponent } from './modals/user-type-modal/user-type-modal.component';
import { UserModalComponent } from './modals/user-modal/user-modal.component';
import { ExpenseModalComponent } from './modals/expense-modal/expense-modal.component';
import { IncomeModalComponent } from './modals/income-modal/income-modal.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PasswordModalComponent } from './modals/password-modal/password-modal.component';
import { AppropriationModalComponent } from './modals/appropriation-modal/appropriation-modal.component';
import { GoalModalComponent } from './modals/goal-modal/goal-modal.component';
import { AccountModalComponent } from './modals/account-modal/account-modal.component';
import { ContributionModalComponent } from './modals/contribution-modal/contribution-modal.component';
import { ContributionConfirmModalComponent } from './modals/contribution-confirm-modal/contribution-confirm-modal.component';
import { KidConfirmModalComponent } from './modals/kid-confirm-modal/kid-confirm-modal.component';
import { StationModalComponent } from './modals/station-modal/station-modal.component';
import { NewsModalComponent } from './modals/news-modal/news-modal.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { KidModalComponent } from './modals/kid-modal/kid-modal.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ImageCropperModule } from 'ngx-image-cropper';
import { KidImageModalComponent } from './modals/kid-image-modal/kid-image-modal.component';
import { ContactModalComponent } from './modals/contact-modal/contact-modal.component';
import { ReportsModalComponent } from './modals/reports-modal/reports-modal.component';
import { ViewsModalComponent } from './modals/views-modal/views-modal.component';
import { ArticleImageModalComponent } from './modals/article-image-modal/article-image-modal.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [LayoutComponent, SidebarComponent, TopbarComponent, FooterComponent, RightsidebarComponent, HorizontalComponent, VerticalComponent, HorizontaltopbarComponent, CountryModalComponent, StationModalComponent, ConferenceModalComponent, CityModalComponent, OrganisationModalComponent, ExpenseCategoryModalComponent, IncomeCategoryModalComponent, CurrencyModalComponent, PaymentMethodModalComponent, UserTypeModalComponent, UserModalComponent, ExpenseModalComponent, IncomeModalComponent, PasswordModalComponent, AppropriationModalComponent, GoalModalComponent, AccountModalComponent, ContributionModalComponent, ContributionConfirmModalComponent, KidConfirmModalComponent, NewsModalComponent, KidModalComponent, KidImageModalComponent, ContactModalComponent, ReportsModalComponent, ViewsModalComponent, ArticleImageModalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    ClickOutsideModule,
    UIModule,
    SimplebarAngularModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
    UIModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    FormsModule,
    AngularMyDatePickerModule,
    NgSelectModule,
    NgxIntlTelInputModule,
    NgbAlertModule,
    CKEditorModule,
    GooglePlaceModule,
    ImageCropperModule,
  ],
  providers: [LanguageService]
})
export class LayoutsModule { }
