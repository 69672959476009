import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AccountDTO } from 'src/app/core/dtos/account.dto';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { OrganisationsService } from 'src/app/core/services/organisations.service';
import { PaymentMethodsService } from 'src/app/core/services/payment-methods.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-account-modal',
  templateUrl: './account-modal.component.html',
  styleUrls: ['./account-modal.component.scss']
})
export class AccountModalComponent implements OnInit {

  entityName: String = 'Accounts';
  submitted: boolean;
  accountForm: FormGroup;
  accountDTO: AccountDTO = new AccountDTO();
  churches:any[] = [];
  payment_methods: any[] = [];
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private accountService: AccountsService, private paymentMethodService: PaymentMethodsService, private organisationService: OrganisationsService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, private utilsService: UtilsService, public formBuilder: FormBuilder) { 
    this.fetchChurches();
    this.fetchPaymentMethods();
  }

  ngOnInit(): void {
    this.accountDTO = this.accountService.selectedAccount;
    console.log(this.accountDTO);
    this.isEdit = this.accountService.isEdit;

    this.accountForm = this.formBuilder.group({
      account_name: [(this.accountDTO ? this.accountDTO.account_name : ''), Validators.required],
      account_number: [(this.accountDTO ? this.accountDTO.account_number : ''), Validators.required],
      branch: [(this.accountDTO ? this.accountDTO.branch : ''), Validators.required],
      payment_method_id: [(this.accountDTO ? this.accountDTO?.payment_method?.id : 0), Validators.required],
    })

    this.accountForm.patchValue({ conference_id: this.accountDTO?.payment_method?.id });
    
  }

  fetchChurches() {

    this.ngxService.start();
    this.utilsService.getDataWithoutTable(this.organisationService.viewActive(), `${this.entityName}`).subscribe(
      data => {
        this.ngxService.stop();
        //set data in service
        this.churches = data;
        //patch the value of select
        // this.addFaqForm.patchValue({ categoryId: this.selectCategories[0]?.id });
      },
      error => {
        this.ngxService.stop();
      });

  }

  fetchPaymentMethods() {

    this.ngxService.start();
    this.utilsService.getDataWithoutTable(this.paymentMethodService.viewActive(), `${this.entityName}`).subscribe(
      data => {
        this.ngxService.stop();
        //set data in service
        this.payment_methods = data;
        //patch the value of select
        // this.addFaqForm.patchValue({ categoryId: this.selectCategories[0]?.id });
      },
      error => {
        this.ngxService.stop();
      });

  }


  get f() {
    return this.accountForm.controls;
  }

  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.accountForm.invalid) {
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      console.log(this.createRequestDTO())
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): AccountDTO {
    var requestDTO = new AccountDTO();
    requestDTO.account_name = this.f.account_name.value;
    requestDTO.account_number = this.f.account_number.value;
    requestDTO.branch = this.f.branch.value;
    requestDTO.payment_method_id = this.f.payment_method_id.value;
    requestDTO.account_id = this.accountDTO.id;
    requestDTO.active = true;
    return requestDTO;
  }

}
