<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="contactForm">
    <div class="modal-header">
        <h4 class="modal-title">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">New </ng-container>
            Contact
        </h4>
        <a class="close" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close noti-icon"></i>
        </a>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="form-group col-4 mb-2">
                <label for="validationCustom02">Label</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="label" placeholder="Enter Label" [ngClass]="{'is-invalid': submitted && f.label.errors,'is-valid': submitted && !f.label.errors}">
                <div *ngIf="submitted && f.label.errors" class="invalid-feedback">
                    <span *ngIf="f.label.errors?.required">Please provide a valid label.</span>
                </div>
                <div *ngIf="submitted && !f.label.errors" class="valid-feedback">
                    <span *ngIf="!f.label.errors">Looks good!</span>
                </div>
            </div>

            <div class="form-group col-4 mb-2">
                <label for="validationCustom02">Contact First Name</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="first_name" placeholder="Enter First Name" [ngClass]="{'is-invalid': submitted && f.first_name.errors,'is-valid': submitted && !f.first_name.errors}">
                <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                    <span *ngIf="f.first_name.errors?.required">Please provide a valid last name.</span>
                </div>
                <div *ngIf="submitted && !f.first_name.errors" class="valid-feedback">
                    <span *ngIf="!f.first_name.errors">Looks good!</span>
                </div>
            </div>

            <div class="form-group col-4 mb-2">
                <label for="validationCustom02">Contact Last Name</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="last_name" placeholder="Enter Last Name" [ngClass]="{'is-invalid': submitted && f.last_name.errors,'is-valid': submitted && !f.last_name.errors}">
                <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                    <span *ngIf="f.last_name.errors?.required">Please provide a valid last name.</span>
                </div>
                <div *ngIf="submitted && !f.last_name.errors" class="valid-feedback">
                    <span *ngIf="!f.last_name.errors">Looks good!</span>
                </div>
            </div>

            <div class="form-group col-4 mb-2">
                <label for="validationCustom02">Contact Email</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="primary_email" placeholder="Enter Email Address" [ngClass]="{'is-invalid': submitted && f.primary_email.errors,'is-valid': submitted && !f.primary_email.errors}">
                <div *ngIf="submitted && f.primary_email.errors" class="invalid-feedback">
                    <span *ngIf="f.primary_email.errors?.required">Please provide a valid email.</span>
                </div>
                <div *ngIf="submitted && !f.primary_email.errors" class="valid-feedback">
                    <span *ngIf="!f.primary_email.errors">Looks good!</span>
                </div>
            </div>

            <div class="form-group col-4 mb-2">
                <label for="validationCustom02">Contact Phone Number</label>
                <ngx-intl-tel-input [cssClass]="'form-control'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Zimbabwe" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="true" [ngClass]="{'is-invalid': submitted && f.primary_phone.errors,'is-valid': submitted && !f.primary_phone.errors}"
                    [numberFormat]="PhoneNumberFormat.National" name="primary_phone" formControlName="primary_phone">
                </ngx-intl-tel-input>
                <div *ngIf="submitted && f.primary_phone.errors" class="invalid-feedback">
                    <span *ngIf="f.primary_phone.errors?.required">Please provide a valid phone number.</span>

                    <span *ngIf="f.primary_phone.errors?.validatePhoneNumber">Please provide a valid
                        phone
                        number.</span>
                </div>
                <div *ngIf="submitted && !f.primary_phone.errors" class="valid-feedback">
                    <span *ngIf="!f.primary_phone.errors">Looks good!</span>
                </div>

            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">Save </ng-container>
            Contact
        </button>
        <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
    </div>
</form>