<div class="modal-header">
    <h4 class="modal-title">
        <ng-container> Kids Reported ({{ kids.length }} Kids Reported) </ng-container>
    </h4>
    <a class="close" aria-label="Close" (click)="close()">
        <i class="mdi mdi-close noti-icon"></i>
    </a>
</div>
<div class="modal-body">
    <div class="table-responsive">
        <table id="basic-datatable" class="table table-hover  table-bordered table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">

            <thead class="thead-light">
                <tr>
                    <th></th>
                    <th style="width: 20px;">
                        No.
                    </th>
                    <th>Name</th>
                    <th>Alias</th>
                    <th>DOB</th>
                    <th>Date Last Seen</th>
                    <th>Gender</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody *ngIf="totalRecords==0">
                <tr>

                    <td colspan="12">
                        <h6 class="text-center">No Records Found</h6>
                    </td>

                </tr>
            </tbody>
            <tbody *ngFor="let table of kids;let i=index">
                <tr [ngClass]="(table.status === 'MISSING') ? '' : 'inactive'">
                    <td align="center">
                        <a class="expand-row" (click)="changeKidValue(i)">
                            +
                        </a>
                    </td>
                    <td>
                        <ngb-highlight [highlightClass]="'text-warning'" [result]="i+1" [term]="searchTerm">
                        </ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [highlightClass]="'text-warning'" [result]="table?.first_name+' '+table?.last_name" [term]="searchTerm"></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [highlightClass]="'text-warning'" [result]="table?.alias" [term]="searchTerm"></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [highlightClass]="'text-warning'" [result]="table?.date_of_birth" [term]="searchTerm"></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [highlightClass]="'text-warning'" [result]="table?.date_last_seen ?? 'N/A'" [term]="searchTerm">
                        </ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [highlightClass]="'text-warning'" [result]="table?.gender" [term]="searchTerm"></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [highlightClass]="'text-warning'" [result]="table?.status" [term]="searchTerm"></ngb-highlight>
                    </td>
                </tr>

                <tr *ngIf="!hidekid[i]" class="sub-table no-bg">
                    <td align="center"> </td>
                    <td colspan="15" class="p-0">
                        <table class="table mb-0 table-striped">
                            <thead class="bg-primary text-white">
                                <tr>
                                    <th colspan="2">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ table?.first_name+' '+table?.last_name }}</td>
                                    <td>
                                        <div class="product-img">
                                            <img [src]="kidsPicUrl+table?.kid_attachments[0].attachment.id" alt="" class="img-fluid mx-auto d-block" data-zoom="assets/images/product/img-1.png">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Alias</td>
                                    <td>{{table?.alias }}</td>
                                </tr>
                                <tr>
                                    <td>Date of Birth</td>
                                    <td>{{table?.date_of_birth }}</td>
                                </tr>
                                <tr>
                                    <td>Gender</td>
                                    <td>{{table?.gender }}</td>
                                </tr>
                                <tr>
                                    <td>Date Last Seen</td>
                                    <td>{{table?.date_last_seen }}</td>
                                </tr>
                                <tr>
                                    <td>Location Last Seen</td>
                                    <td>{{table?.location_last_seen }}</td>
                                </tr>
                                <tr>
                                    <td>Latitude</td>
                                    <td>{{table?.latitude }}</td>
                                </tr>
                                <tr>
                                    <td>Longitude</td>
                                    <td>{{table?.longitude }}</td>
                                </tr>
                                <tr>
                                    <td>Height</td>
                                    <td>{{table?.height }}</td>
                                </tr>
                                <tr>
                                    <td>Weight</td>
                                    <td>{{table?.weight }}</td>
                                </tr>
                                <tr>
                                    <td>Skin</td>
                                    <td>{{table?.skin }}</td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td>
                                        <ngb-highlight *ngIf="table.active" [result]="'ACTIVE'" [term]="searchTerm">
                                        </ngb-highlight>
                                        <ngb-highlight *ngIf="!table.active" [result]="'INACTIVE'" [term]="searchTerm">
                                        </ngb-highlight>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Date Created</td>
                                    <td>{{table?.created_at | date : 'short'}}</td>
                                </tr>
                                <tr>
                                    <td>Date Updated</td>
                                    <td>{{table?.updated_at | date : 'short'}}</td>
                                </tr>

                                <tr class="bg-primary text-white">
                                    <td colspan="2">
                                        <h6><b>Primary Contact</b></h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Name</td>
                                    <td>{{table?.contact?.first_name+ ' ' + table?.contact?.last_name}}</td>
                                </tr>

                                <tr>
                                    <td>Email</td>
                                    <td>{{table?.contact?.primary_email }}</td>
                                </tr>

                                <tr>
                                    <td>Phone Number</td>
                                    <td>{{table?.contact?.primary_phone }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
</div>