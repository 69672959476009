import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IMyDateModel } from 'angular-mydatepicker';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IncomeDTO } from 'src/app/core/dtos/income.dto';
import { OrganisationsService } from 'src/app/core/services/organisations.service';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { IncomeCategoriesService } from 'src/app/core/services/income-categories.service';
import { IncomesService } from 'src/app/core/services/incomes.service';
import { PaymentMethodsService } from 'src/app/core/services/payment-methods.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-income-modal',
  templateUrl: './income-modal.component.html',
  styleUrls: ['./income-modal.component.scss']
})
export class IncomeModalComponent implements OnInit {

  entityName: String = 'Incomes';
  submitted: boolean;
  incomeForm: FormGroup;
  incomeDTO: IncomeDTO = new IncomeDTO();
  currencies: any[] = [];
  payment_methods: any[] = [];
  income_categories: any[] = [];
  churches: any[] = [];
  members: any[] = [];
  paymentMethodAccounts: any[] = [];
  accounts: any[] = [];
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private incomeService: IncomesService, private currencyService: CurrenciesService, private paymentMethodService: PaymentMethodsService, private incomeCategoryService: IncomeCategoriesService, private organisationService: OrganisationsService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, public utilsService: UtilsService, public formBuilder: FormBuilder) {
    this.fetchIncomeItems();
  }

  ngOnInit(): void {
    this.incomeDTO = this.incomeService.selectedIncome;
    console.log(this.incomeDTO);
    this.isEdit = this.incomeService.isEdit;

    if (this.isEdit) {

      this.incomeForm = this.formBuilder.group({
        amount: [(this.incomeDTO ? (this.incomeDTO.amount/100) : ''), Validators.required],
        reference_date: [(this.incomeDTO ? this.incomeDTO.reference_date : ''), Validators.required],
        reference: [(this.incomeDTO ? this.incomeDTO.reference : ''), Validators.required],
        currency_id: [(this.incomeDTO ? this.incomeDTO?.currency?.id : 0), Validators.required],
        payment_method_id: [(this.incomeDTO ? this.incomeDTO?.payment_method?.id : 0), Validators.required],
        income_category_id: [(this.incomeDTO ? this.incomeDTO?.income_category?.id : 0), Validators.required],
        member_id: [(this.incomeDTO ? this.incomeDTO?.member_id : 0), Validators.required],
        account_id: [(this.incomeDTO ? this.incomeDTO?.account?.id : 0), Validators.required]
      })

    }else{

      this.incomeForm = this.formBuilder.group({
        amounts: this.formBuilder.array([]),
        reference_date: [(this.incomeDTO ? this.incomeDTO.reference_date : ''), Validators.required],
        reference: [(this.incomeDTO ? this.incomeDTO.reference : ''), Validators.required],
        currency_id: [(this.incomeDTO ? this.incomeDTO?.currency?.id : 0), Validators.required],
        payment_method_id: [(this.incomeDTO ? this.incomeDTO?.payment_method?.id : 0), Validators.required],
        member_id: [(this.incomeDTO ? this.incomeDTO?.member?.id : 0), Validators.required],
        account_id: [(this.incomeDTO ? this.incomeDTO?.account?.id : 0), Validators.required]
      })

    }

    this.incomeForm.patchValue({ currency_id: this.incomeDTO?.currency?.id });
    this.incomeForm.patchValue({ payment_method_id: this.incomeDTO?.payment_method?.id });
    this.incomeForm.patchValue({ income_category_id: this.incomeDTO?.income_category?.id });
    this.incomeForm.patchValue({ member_id: this.incomeDTO?.member?.id });
    this.incomeForm.patchValue({ account_id: this.incomeDTO?.account?.id });

    let createDate = new Date(this.incomeDTO.reference_date as string);
    let initDate: IMyDateModel = {
      isRange: false, singleDate: {
        date: {
          year: createDate?.getFullYear(),
          month: createDate?.getMonth() + 1,
          day: createDate?.getDate(),
        }
      }
    };

    this.incomeForm.patchValue({ reference_date: initDate });

  }

  fetchIncomeItems() {
    this.ngxService.start();
    this.utilsService.getDataWithoutTable(this.incomeService.viewIncomeItems(), `Fetching Currencies`).subscribe(
      data => {
        this.ngxService.stop();
        //set data in service
        this.currencies = data.currencies;
        this.payment_methods = data.payment_methods;
        this.income_categories = data.income_categories;
        this.churches = data.churches;
        this.members = data.members;
        this.accounts = data.accounts;
        //patch the value of select
        this.structureAccounts(this.incomeDTO?.payment_method)
      },
      error => {
        this.ngxService.stop();
      });
  }

  get f() {
    return this.incomeForm.controls;
  }

  amounts(): FormArray {
    return this.incomeForm.get('amounts') as FormArray
  }

  newAmount(): FormGroup {
    return this.formBuilder.group({
      amount: ['', Validators.required],
      income_category_id: [0, Validators.required],
    })
  }

  addAmount(){
    this.amounts().push(this.newAmount())
  }

  removeAmount(i: number){
    this.amounts().removeAt(i);
  }

  close() {
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.incomeForm.invalid) {
      console.log(this.incomeForm)
      console.log("invalid form")
      this.error = 'Please make sure you fill in all the required fields'
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): IncomeDTO {
    var requestDTO = new IncomeDTO();
    requestDTO.items = typeof this.f.amounts == 'undefined' ? '' : this.f.amounts.value;
    requestDTO.amount = typeof this.f.amount == "undefined" ? '' : this.f.amount.value;
    requestDTO.reference_date = this.utilsService.getUpdateDateFromDatePicker(this.f.reference_date.value);
    requestDTO.reference = this.f.reference.value;
    requestDTO.currency_id = this.f.currency_id.value;
    requestDTO.payment_method_id = this.f.payment_method_id.value;
    requestDTO.member_id = this.f.member_id.value;
    requestDTO.account_id = this.f.account_id.value;
    requestDTO.income_id = this.incomeDTO.id;
    requestDTO.income_category_id = typeof this.f.income_category_id == "undefined" ? '' : this.f.income_category_id.value;
    requestDTO.active = true;
    return requestDTO;
  }

  changePaymentMethod(selectedPaymentMethod) {
    this.structureAccounts(selectedPaymentMethod);
  }

  structureAccounts(selectedPaymentMethod) {
    this.paymentMethodAccounts = []
    //find accounts with the same payment method
    let newAccounts = [...this.accounts]
    var selectedAccounts = newAccounts.filter((account) => account.payment_method?.id == selectedPaymentMethod?.id)
    console.log(selectedAccounts)
    //structure accounts data
    if (selectedAccounts.length !== 0) {
        //save currencies
        selectedAccounts.forEach(element => {
            let account = {
                value: element.id,
                label: element.account_name
            }
            this.paymentMethodAccounts.push(account)
            if(this.incomeDTO?.payment_method !== null){
              console.log("Yes")
              this.incomeForm.patchValue({ account_id: this.incomeDTO?.account?.id });
            }
        })
        // setAccounts(accounts);
    }else{
      this.incomeForm.patchValue({ account_id: '' });
      this.toastr.warning('Please make sure the selected payment method has an account.')
    }
  }

}
