import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ArticleDTO } from '../dtos/article.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  public selectedArticle: ArticleDTO = new ArticleDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/articles/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive() {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/articles/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/articles/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeArticleStatus(article_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/articles/${article_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateArticle(station: ArticleDTO, article_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/articles/${article_id}`, station)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createArticle(station: ArticleDTO, image) {

    let url = this.serializeUrl(station)
    var formdata = new FormData();
    formdata.append('image', image)

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/articles/?${url}`, formdata)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateArticleImage(article_id, image) {

    var formdata = new FormData();
    formdata.append('image', image)
    return this.http.put<any>(AppSettings.API_ENDPOINT + `/articles/image/${article_id}`, formdata)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  serializeUrl(obj) {
    var str = "";
    for (var key in obj) {
      if (key != 'file') {
        if (str != "") {
          str += "&";
        }
      }
      str += key + "=" + encodeURIComponent(obj[key]);
    }

    return str;
  }
}
