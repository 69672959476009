import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordDTO } from 'src/app/core/dtos/password.dto';
import { PasswordValidation } from 'src/app/core/utils/passwordValidation';

@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss']
})
export class PasswordModalComponent implements OnInit {

  submitted: boolean;
  updatePasswordForm: FormGroup;
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.updatePasswordForm = this.formBuilder.group({
      old_password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]]
    }, {
      validator: PasswordValidation.MatchPassword // password validation method
    });
    
  }


  get f() {
    return this.updatePasswordForm.controls;
  }

  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.updatePasswordForm.invalid) {
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): PasswordDTO {
    var requestDTO  = new PasswordDTO();
    requestDTO.old_password = this.f.old_password.value;
    requestDTO.new_password = this.f.password.value;
    return requestDTO;
  }

}
