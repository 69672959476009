import { Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IMyDateModel } from "angular-mydatepicker";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { KidDTO } from "src/app/core/dtos/kid.dto";
import { KidService } from "src/app/core/services/kid.service";
import { StationsService } from "src/app/core/services/stations.service";
import { UtilsService } from "src/app/core/utils/utils.service";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";
import {
  ImageCroppedEvent,
  LoadedImage,
  base64ToFile,
  ImageTransform,
  Dimensions,
} from "ngx-image-cropper";

@Component({
  selector: "app-kid-modal",
  templateUrl: "./kid-modal.component.html",
  styleUrls: ["./kid-modal.component.scss"],
})
export class KidModalComponent implements OnInit {
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Zimbabwe,
    CountryISO.SouthAfrica,
  ];

  imageChangedEvent: any = "";
  croppedImage: any = "";
  imageToSend: any;
  fileName: any = "";
  defaultAlreadyExists: boolean;
  showCropper = false;

  entityName: String = "Kids";
  submitted: boolean;
  kidForm: FormGroup;
  kidDTO: KidDTO = new KidDTO();
  stations: any[] = [];
  isEdit = false;
  genders: any[] = [];
  error = "";
  typesubmit: boolean;

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  constructor(
    private activeModal: NgbActiveModal,
    private kidService: KidService,
    private stationService: StationsService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    public utilsService: UtilsService,
    public formBuilder: FormBuilder
  ) {
    this.fetchStations();

    this.genders = [
      {
        id: "MALE",
        name: "MALE",
      },
      {
        id: "FEMALE",
        name: "FEMALE",
      },
    ];
  }

  ngOnInit(): void {
    this.kidDTO = this.kidService.selectedKid;
    console.log(this.kidDTO);
    this.isEdit = this.kidService.isEdit;

    if (this.isEdit) {
      this.kidForm = this.formBuilder.group({
        first_name: [
          this.kidDTO ? this.kidDTO.first_name : "",
          Validators.required,
        ],
        last_name: [
          this.kidDTO ? this.kidDTO.last_name : "",
          Validators.required,
        ],
        alias: [this.kidDTO ? this.kidDTO.alias : "", Validators.required],
        gender: [this.kidDTO ? this.kidDTO.gender : "", Validators.required],
        circumstances: [
          this.kidDTO ? this.kidDTO.circumstances : "",
          Validators.required,
        ],
        location_last_seen: [
          this.kidDTO ? this.kidDTO.location_last_seen : "",
          Validators.required,
        ],
        // latitude: [(this.kidDTO ? this.kidDTO.latitude : ''), Validators.required],
        // longitude: [(this.kidDTO ? this.kidDTO.longitude : ''), Validators.required],
        height: [this.kidDTO ? this.kidDTO.height : "", Validators.required],
        weight: [this.kidDTO ? this.kidDTO.weight : "", Validators.required],
        skin: [this.kidDTO ? this.kidDTO.skin : ""],
        date_of_birth: [
          this.kidDTO ? this.kidDTO.date_of_birth : "",
          Validators.required,
        ],
        date_last_seen: [
          this.kidDTO ? this.kidDTO.date_last_seen : "",
          Validators.required,
        ],
        image: [""],

        station_id: [
          this.kidDTO ? this.kidDTO?.station?.id : "",
          Validators.required,
        ],
      });
    } else {
      this.kidForm = this.formBuilder.group({
        contacts: this.formBuilder.array([]),
        first_name: [
          this.kidDTO ? this.kidDTO.first_name : "",
          Validators.required,
        ],
        last_name: [
          this.kidDTO ? this.kidDTO.last_name : "",
          Validators.required,
        ],
        alias: [this.kidDTO ? this.kidDTO.alias : "", Validators.required],
        gender: [
          this.kidDTO ? this.kidDTO.gender : "MALE",
          Validators.required,
        ],
        circumstances: [
          this.kidDTO ? this.kidDTO.circumstances : "",
          Validators.required,
        ],
        location_last_seen: [
          this.kidDTO ? this.kidDTO.location_last_seen : "",
          Validators.required,
        ],
        // latitude: [(this.kidDTO ? this.kidDTO.latitude : ''), Validators.required],
        // longitude: [(this.kidDTO ? this.kidDTO.longitude : ''), Validators.required],
        height: [this.kidDTO ? this.kidDTO.height : "", Validators.required],
        weight: [this.kidDTO ? this.kidDTO.weight : "", Validators.required],
        skin: [this.kidDTO ? this.kidDTO.skin : ""],
        date_of_birth: [
          this.kidDTO ? this.kidDTO.date_of_birth : "",
          Validators.required,
        ],
        date_last_seen: [
          this.kidDTO ? this.kidDTO.date_last_seen : "",
          Validators.required,
        ],
        image: [""],
        contact_label: ["", Validators.required],
        contact_first_name: ["", Validators.required],
        contact_last_name: ["", Validators.required],
        contact_primary_phone: ["", Validators.required],
        contact_primary_email: ["", Validators.required],

        station_id: [
          this.kidDTO ? this.kidDTO?.station?.id : 0,
          Validators.required,
        ],
      });
    }

    this.typesubmit = false;
    this.kidForm.patchValue({ station_id: this.kidDTO?.station?.id });

    
    let createDate = new Date(this.kidDTO.date_of_birth as string);
    let initDate: IMyDateModel = {
      isRange: false,
      singleDate: {
        date: {
          year: createDate?.getFullYear(),
          month: createDate?.getMonth() + 1,
          day: createDate?.getDate(),
        },
      },
    };

    this.kidForm.patchValue({ date_of_birth: initDate });

    let lastSeenDate = new Date(this.kidDTO.date_last_seen as string);
    let initSeenDate: IMyDateModel = {
      isRange: false,
      singleDate: {
        date: {
          year: lastSeenDate?.getFullYear(),
          month: lastSeenDate?.getMonth() + 1,
          day: lastSeenDate?.getDate(),
        },
      },
    };

    this.kidForm.patchValue({ date_last_seen: initSeenDate });
  }

  fetchStations() {
    this.ngxService.start();
    this.utilsService
      .getDataWithoutTable(
        this.stationService.viewActive(),
        `Fetching Stations`
      )
      .subscribe(
        (data) => {
          this.ngxService.stop();
          //set data in service
          this.stations = data;
          console.log(this.stations);
          //patch the value of select
          this.kidForm.patchValue({ station_id: this.stations[0].id });
        },
        (error) => {
          this.ngxService.stop();
        }
      );
  }

  get f() {
    return this.kidForm.controls;
  }

  /**
   * Type validation form submit data
   */
  typeSubmit() {
    this.typesubmit = true;
  }

  contacts(): FormArray {
    return this.kidForm.get("contacts") as FormArray;
  }

  newContact(): FormGroup {
    return this.formBuilder.group({
      contact_label: ["", Validators.required],
      contact_first_name: [0, Validators.required],
      contact_last_name: [0, Validators.required],
      contact_primary_phone: [0, Validators.required],
      contact_primary_email: [0, Validators.required],
    });
  }

  addContact() {
    this.contacts().push(this.newContact());
  }

  removeContact(i: number) {
    this.contacts().removeAt(i);
  }

  close() {
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.kidForm.invalid) {
      console.log(this.kidForm);
      console.log("invalid form");
      this.error = "Please make sure you fill in all the required fields";
      return;
    } else {
      //add to list
      //Pass value
      console.log("Passing Value from modal");
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): KidDTO {
    var requestDTO = new KidDTO();
    requestDTO.first_name = this.f.first_name.value;
    requestDTO.last_name = this.f.last_name.value;
    requestDTO.alias = this.f.alias.value;
    requestDTO.date_of_birth = this.utilsService.getUpdateDateFromDatePicker(
      this.f.date_of_birth.value
    );
    requestDTO.date_last_seen = this.utilsService.getUpdateDateFromDatePicker(
      this.f.date_last_seen.value
    );
    requestDTO.circumstances = this.f.circumstances.value;
    requestDTO.station_id = this.f.station_id.value;
    requestDTO.location_last_seen = this.f.location_last_seen.value;
    // requestDTO.longitude = this.f.longitude.value;
    // requestDTO.latitude = this.f.latitude.value;
    requestDTO.gender = this.f.gender.value;
    requestDTO.height = this.f.height.value;
    requestDTO.weight = this.f.weight.value;
    // contact
    if (!this.isEdit) {
      requestDTO.contact_label = this.f.contact_label.value;
      requestDTO.contact_first_name = this.f.contact_first_name.value;
      requestDTO.contact_last_name = this.f.contact_last_name.value;
      requestDTO.contact_primary_phone =
        this.utilsService.getPhonenumberAsString(
          this.f.contact_primary_phone.value
        );
      requestDTO.contact_primary_email = this.f.contact_primary_email.value;
      requestDTO.file = this.dataURLtoFile(this.croppedImage, this.fileName);
    }

    // requestDTO.skin = this.f.skin.value;
    // requestDTO.active = true;
    // requestDTO.file = this.croppedImage;
    return requestDTO;
  }

  public handleAddressChange(address: Address) {
    // Do some stuff
  }

  fileChangeEvent(event: any): void {
    // this.imageChangedEvent = event;

    if (event.target.files && event.target.files[0]) {
      this.fileName = event.target.files[0].name;

      if (
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpg"
      ) {
        if (event.target.files[0].size > 10000000) {
          // checking size here - 2MB}
          console.log("Image Too Big");
          this.f["image"].setValue(null);
          this.imageChangedEvent = null;
          this.croppedImage = null;
          this.toastr.error("Image is too big.");
          return;
        }
        this.imageChangedEvent = event;
      } else {
        console.log("type is not image");
        this.f["image"].setValue(null);
        this.imageChangedEvent = null;
        this.croppedImage = null;
        this.toastr.error("File selected is not an image");
      }
    } else {
      console.log("Nothing selected");
      // this.toastr.error('Nothing selected');
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imageToSend = event;
    console.log(event);
    // let file:File = event.target.files[0];
  }

  imageLoaded() {
    // show cropper
    // this.showCropper = true;
    console.log("Image loaded");
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  addImage() {
    console.log("this is the cropped image");
    // console.log(this.croppedImage);
    this.submitted = true;
    // stop here if form is invalid
    if (this.kidForm.invalid) {
      console.log(this.kidForm);
      return;
    } else {
      //add to list
      //Pass value
      if (this.f.default.value === true && this.defaultAlreadyExists === true) {
        this.toastr.error("A default product variation already exists");
        return;
      }
      console.log("Passing Value from modal");
      this.activeModal.close(this.createRequestDTO());
    }
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}
