import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 200,
        label: 'Dashboard',
        isTitle: true
    },
    {
        id: 201,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: '/dashboard',
    },
    {
        id: 202,
        isLayout: true
    },
    {
        id: 203,
        label: 'Modules',
        isTitle: true
    },
    {
        id: 204,
        label: 'Countries',
        icon: 'bx-globe',
        link: '/countries/view',
        permission: ["view_country"],
    },
    {
        id: 205,
        label: 'Stations',
        icon: 'bx-analyse',
        link: '/stations/view',
        permission: ["view_station"],
        
    },
    {
        id: 206,
        label: 'News',
        icon: 'bx-news',
        link: '/news/view',
        permission: ["view_article"],
    },
    {
        id: 207,
        label: 'Donations',
        icon: 'bx-credit-card-alt',
        link: '/donations/view',
        permission: ["view_donation"],
    },
    {
        id: 208,
        label: 'Organisations',
        icon: 'bx-clinic',
        link: '/organisations/view',
        permission: ["view_organisation"],
    },
    {
        id: 209,
        label: 'Kids',
        icon: 'bx-user',
        link: '/kids/view',
        permission: ["view_kid"],
    },
    {
        id: 210,
        label: 'Contacts',
        icon: 'bxs-contact',
        link: '/contacts/view',
        permission: ["view_kid"],
    },
    // {
    //     id: 212,
    //     label: 'Incomes',
    //     icon: 'bx-wallet',
    //     permission: ["view_income_category", "view_income", "view_appropriation"],
    //     subItems: [
    //         {
    //             id: 213,
    //             label: 'Appropriations',
    //             link: '/incomes/appropriations',
    //             permission: ["view_appropriation"],
    //             parentId: 212
    //         },
    //         {
    //             id: 214,
    //             label: 'Categories',
    //             link: '/incomes/categories',
    //             permission: ["view_income_category"],
    //             parentId: 212
    //         },
    //         {
    //             id: 215,
    //             label: 'Incomes',
    //             link: '/incomes/view',
    //             permission: ["view_income"],
    //             parentId: 212
    //         },
    //     ]
    // },
    // {
    //     id: 224,
    //     label: 'Goals',
    //     icon: 'bx-target-lock',
    //     permission: ["view_goal", "view_goal"],
    //     subItems: [
    //         {
    //             id: 225,
    //             label: 'Goals',
    //             link: '/goals/view',
    //             permission: ["view_goal"],
    //             parentId: 224
    //         },
    //         {
    //             id: 226,
    //             label: 'Contributions',
    //             link: '/goals/contributions',
    //             permission: ["view_goal"],
    //             parentId: 224
    //         },
    //     ]
    // },
    // {
    //     id: 216,
    //     label: 'Currencies',
    //     icon: 'bx-diamond',
    //     link: '/currencies/view',
    //     permission: ["view_currency"],
    // },
    // {
    //     id: 217,
    //     label: 'Payment Methods',
    //     icon: 'bx-credit-card-alt',
    //     link: '/payment-methods/view',
    //     permission: ["view_payment_method"],
    // },
    {
        id: 218,
        label: 'Users',
        icon: 'bx-user-pin',
        permission: ["view_user_type", "view_user"],
        subItems: [
            {
                id: 219,
                label: 'Roles',
                link: '/users/user-types',
                permission: ["view_user_type"],
                parentId: 218
            },
            {
                id: 220,
                label: 'Users',
                link: '/users/view',
                permission: ["view_user"],
                parentId: 218
            },
        ]
    },
    {
        id: 221,
        label: 'Reports',
        icon: 'bx-pie-chart-alt-2',
        permission: ["view_kids_report"],
        subItems: [
            {
                id: 222,
                label: 'Kids',
                link: '/reports/kids',
                permission: ["view_kids_report"],
                parentId: 221
            },
            {
                id: 223,
                label: 'Users',
                link: '/reports/users',
                permission: ["view_users_report"],
                parentId: 221,
            },
            {
                id: 224,
                label: 'Logins',
                link: '/reports/logins',
                permission: ["view_logins_report"],
                parentId: 221,
            },
            {
                id: 225,
                label: 'Activity',
                link: '/reports/activity',
                permission: ["view_activities_report"],
                parentId: 221,
            }
        ]
    }
];

