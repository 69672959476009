<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="goalForm">
    <div class="modal-header">
        <h4 class="modal-title">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">New </ng-container>
            Goal
        </h4>
        <a class="close" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close noti-icon"></i>
        </a>
    </div>
    <div class="modal-body">
        <div class="form-group mb-2">
            <label for="validationCustom02">Goal Name</label>
            <input type="text" class="form-control" id="validationCustom02" formControlName="name" placeholder="Enter Goal Name" [ngClass]="{'is-invalid': submitted && f.name.errors,'is-valid': submitted && !f.name.errors}">
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <span *ngIf="f.name.errors?.required">Please provide a valid Goal name.</span>
            </div>
            <div *ngIf="submitted && !f.name.errors" class="valid-feedback">
                <span *ngIf="!f.name.errors">Looks good!</span>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-6 form-group">
                <label for="validationCustom02">Target Amount</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="amount" placeholder="Enter Target Amount" [ngClass]="{'is-invalid': submitted && f.name.errors,'is-valid': submitted && !f.name.errors}">
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <span *ngIf="f.name.errors?.required">Please provide a valid Goal name.</span>
                </div>
                <div *ngIf="submitted && !f.name.errors" class="valid-feedback">
                    <span *ngIf="!f.name.errors">Looks good!</span>
                </div>
            </div>
            <div class="col-6 form-group">
                <label class="control-label">Currency</label>
                <ng-select formControlName="currency_id" [items]="currencies" placeholder="Select Currency" bindLabel="name" bindValue="id" [ngClass]="{'is-invalid': submitted && f.currency_id.errors,'is-valid': submitted && !f.currency_id.errors}">
                </ng-select>
                <div *ngIf="submitted && f.currency_id.errors" class="invalid-feedback">
                    <div *ngIf="f.currency_id.errors.required">This value is required</div>
                </div>
            </div>
        </div>
        <div class="form-group mb-2">
            <label class="control-label">Appropriation</label>
            <ng-select formControlName="appropriation_id" [items]="appropriations" placeholder="Select Appropriation" bindLabel="name" bindValue="id" [ngClass]="{'is-invalid': submitted && f.appropriation_id.errors,'is-valid': submitted && !f.appropriation_id.errors}">
            </ng-select>
            <div *ngIf="submitted && f.appropriation_id.errors" class="invalid-feedback">
                <div *ngIf="f.appropriation_id.errors.required">This value is required</div>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-lg-6">
                <div class="form-group">

                    <label for="validationCustom02">Start Date</label>
                    <div class="input-group">
                        <input class="form-control" (click)="dp.toggleCalendar()" placeholder="Start Date" angular-mydatepicker name="start_date" formControlName="start_date" [options]="utilsService.myFututeOptions" #dp="angular-mydatepicker" [ngClass]="{'is-invalid': submitted && f.start_date.errors, 'is-valid': submitted && !f.start_date.errors}"
                        />
                        <div class="input-group-append">
                            <button type="button" class="btn btn-primary" (click)="dp.toggleCalendar()">
                                <i class="bx bx-calendar"></i>
                            </button>
                        </div>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-danger" (click)="dp.clearDate()">
                                <i class="bx bx-x"></i>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback">
                        <span *ngIf="f.start_date.errors?.required">Please provide a valid end date.</span>
                    </div>
                    <div *ngIf="submitted && !f.start_date.errors" class="valid-feedback">
                        <span *ngIf="!f.start_date.errors">Looks good!</span>
                    </div>
                </div>

            </div>

            <div class="col-lg-6">
                <div class="form-group">

                    <label for="validationCustom02">End Date</label>
                    <div class="input-group">
                        <input class="form-control" (click)="dp2.toggleCalendar()" placeholder="End Date" angular-mydatepicker name="end_date" formControlName="end_date" [options]="utilsService.myFututeOptions" #dp2="angular-mydatepicker" [ngClass]="{'is-invalid': submitted && f.end_date.errors, 'is-valid': submitted && !f.end_date.errors}"
                        />
                        <div class="input-group-append">
                            <button type="button" class="btn btn-primary" (click)="dp2.toggleCalendar()">
                                <i class="bx bx-calendar"></i>
                            </button>
                        </div>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-danger" (click)="dp2.clearDate()">
                                <i class="bx bx-x"></i>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback">
                        <span *ngIf="f.end_date.errors?.required">Please provide a valid end date.</span>
                    </div>
                    <div *ngIf="submitted && !f.end_date.errors" class="valid-feedback">
                        <span *ngIf="!f.end_date.errors">Looks good!</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">Save </ng-container>
            Goal
        </button>
        <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
    </div>
</form>