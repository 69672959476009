import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ContactDTO } from '../dtos/contact.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  public selectedContact: ContactDTO = new ContactDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/contacts/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive() {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/contacts/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/contacts/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeContactStatus(contact_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/contacts/${contact_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateContact(contact: ContactDTO, contact_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/contacts/${contact_id}`, contact)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  viewContactItems() {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/contacts/contact/items/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  createContact(contact: ContactDTO) {

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/contacts/`, contact)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }
}
