import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OrganisationDTO } from 'src/app/core/dtos/organisation.dto';
import { OrganisationsService } from 'src/app/core/services/organisations.service';
import { CitiesService } from 'src/app/core/services/cities.service';
import { ConferencesService } from 'src/app/core/services/conferences.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { CountriesService } from 'src/app/core/services/countries.service';

@Component({
  selector: 'app-organisation-modal',
  templateUrl: './organisation-modal.component.html',
  styleUrls: ['./organisation-modal.component.scss']
})
export class OrganisationModalComponent implements OnInit {

  entityName: String = 'Organisations';
  submitted: boolean;
  organisationForm: FormGroup;
  organisationDTO: OrganisationDTO = new OrganisationDTO();
  countries: any[] = [];
  conferences: any[] = [];
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private countryService: CountriesService, private conferenceService: ConferencesService, private organisationService: OrganisationsService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, private utilsService: UtilsService, public formBuilder: FormBuilder) {
    this.fetchCountries();
  }

  ngOnInit(): void {
    this.organisationDTO = this.organisationService.selectedOrganisation;
    console.log(this.organisationDTO);
    this.isEdit = this.organisationService.isEdit;

    this.organisationForm = this.formBuilder.group({
      name: [(this.organisationDTO ? this.organisationDTO.name : ''), Validators.required],
      address: [(this.organisationDTO ? this.organisationDTO.address : ''), Validators.required],
      country_id: [(this.organisationDTO ? this.organisationDTO?.country?.id : 0), Validators.required],
    })

    this.organisationForm.patchValue({ city_id: this.organisationDTO?.country?.id });

  }

  fetchCountries() {

    this.ngxService.start();
    this.utilsService.getDataWithoutTable(this.countryService.viewActive(), `${this.entityName}`).subscribe(
      data => {
        this.ngxService.stop();
        //set data in service
        this.countries = data;
        //patch the value of select
        // this.addFaqForm.patchValue({ categoryId: this.selectCategories[0]?.id });
      },
      error => {
        this.ngxService.stop();
      });

  }

  get f() {
    return this.organisationForm.controls;
  }

  close() {
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.organisationForm.invalid) {
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): OrganisationDTO {
    var requestDTO = new OrganisationDTO();
    requestDTO.name = this.f.name.value;
    requestDTO.address = this.f.address.value;
    requestDTO.country_id = this.f.country_id.value;
    requestDTO.organisation_id = this.organisationDTO.id;
    requestDTO.active = true;
    return requestDTO;
  }

}
