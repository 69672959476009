import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IMyDateModel } from 'angular-mydatepicker';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GoalDTO } from 'src/app/core/dtos/goal.dto';
import { OrganisationsService } from 'src/app/core/services/organisations.service';
import { GoalsService } from 'src/app/core/services/goals.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-goal-modal',
  templateUrl: './goal-modal.component.html',
  styleUrls: ['./goal-modal.component.scss']
})
export class GoalModalComponent implements OnInit {

  entityName: String = 'Goals';
  submitted: boolean;
  goalForm: FormGroup;
  goalDTO: GoalDTO = new GoalDTO();
  currencies:any[] = [];
  appropriations:any[] = [];
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private goalService: GoalsService, private OrganisationsService: OrganisationsService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, public utilsService: UtilsService, public formBuilder: FormBuilder) { 
    this.fetchGoalItems();
  }

  ngOnInit(): void {
    this.goalDTO = this.goalService.selectedGoal;
    console.log(this.goalDTO);
    this.isEdit = this.goalService.isEdit;

    if (this.isEdit) { 

      this.goalForm = this.formBuilder.group({
        name: [(this.goalDTO ? this.goalDTO.name : ''), Validators.required],
        amount: [(this.goalDTO ? (this.goalDTO.amount/100): ''), Validators.required],
        currency_id: [(this.goalDTO ? this.goalDTO?.currency?.id : 0), Validators.required],
        appropriation_id: [(this.goalDTO ? this.goalDTO?.appropriation?.id : 0), Validators.required],
        start_date: [(this.goalDTO ? this.goalDTO.start_date : ''), Validators.required],
        end_date: [(this.goalDTO ? this.goalDTO.end_date : ''), Validators.required],
      })

    }else{

      this.goalForm = this.formBuilder.group({
        name: ['', Validators.required],
        amount: ['', Validators.required],
        currency_id: [(this.goalDTO ? this.goalDTO?.currency?.id : 0), Validators.required],
        appropriation_id: [(this.goalDTO ? this.goalDTO?.appropriation?.id : 0), Validators.required],
        start_date: [(this.goalDTO ? this.goalDTO.start_date : ''), Validators.required],
        end_date: [(this.goalDTO ? this.goalDTO.end_date : ''), Validators.required],
      })

    }

    this.goalForm.patchValue({ currency_id: this.goalDTO?.currency?.id });
    this.goalForm.patchValue({ appropriation_id: this.goalDTO?.appropriation?.id });

    let startDate = new Date(this.goalDTO.start_date as string);
    let initDate: IMyDateModel = {
      isRange: false, singleDate: {
        date: {
          year: startDate?.getFullYear(),
          month: startDate?.getMonth() + 1,
          day: startDate?.getDate(),
        }
      }
    };

    let endDate = new Date(this.goalDTO.end_date as string);
    let initEndDate: IMyDateModel = {
      isRange: false, singleDate: {
        date: {
          year: endDate?.getFullYear(),
          month: endDate?.getMonth() + 1,
          day: endDate?.getDate(),
        }
      }
    };
    
    this.goalForm.patchValue({ start_date: initDate });
    this.goalForm.patchValue({ end_date: initEndDate });
  }

  fetchGoalItems() {
    this.ngxService.start();
    this.utilsService.getDataWithoutTable(this.goalService.viewGoalItems(), `Fetching Currencies`).subscribe(
      data => {
        this.ngxService.stop();
        //set data in service
        this.currencies = data.currencies;
        this.appropriations = data.appropriations;
        //patch the value of select
      },
      error => {
        this.ngxService.stop();
      });
  }


  get f() {
    return this.goalForm.controls;
  }

  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.goalForm.invalid) {
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): GoalDTO {
    var requestDTO = new GoalDTO();
    requestDTO.name = this.f.name.value;
    requestDTO.amount = this.f.amount.value;
    requestDTO.currency_id = this.f.currency_id.value;
    requestDTO.appropriation_id = this.f.appropriation_id.value;
    requestDTO.start_date = this.utilsService.getUpdateDateFromDatePicker(this.f.start_date.value);
    requestDTO.end_date = this.utilsService.getUpdateDateFromDatePicker(this.f.end_date.value);
    requestDTO.goal_id = this.goalDTO.id;
    requestDTO.active = true;
    return requestDTO;
  }


}
