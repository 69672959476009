import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConferenceDTO } from '../dtos/conference.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class ConferencesService {

  public selectedConference: ConferenceDTO = new ConferenceDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/conferences/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/conferences/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/conferences/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeConferenceStatus(conference_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/conferences/${conference_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateConference(conference: ConferenceDTO, conference_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/conferences/${conference_id}`, conference)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createConference(conference: ConferenceDTO){

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/conferences/`, conference)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }
}
