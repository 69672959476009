<ngx-ui-loader [loaderId]="'master'" blur="12" fgsSize="75" threshold="2000" bgsColor="#FFFFFF" textColor="#000000" overlayColor="rgba(255,255,255,0.8)" fgsColor="#28367C" pbColor="#28367C" fgsType="ball-scale-multiple" bgsType="ball-spin-clockwise" [text]="loadingText"></ngx-ui-loader>

<div id="layout-wrapper">

    <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
    </app-topbar>

    <app-sidebar [isCondensed]="isCondensed"></app-sidebar>

    <div class="main-content">
        <div class="page-content">
            <!-- content -->
            <router-outlet></router-outlet>
        </div>

        <!-- footer -->
        <app-footer></app-footer>
    </div>
</div>

<app-rightsidebar></app-rightsidebar>