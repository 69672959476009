

export class ExpenseCategoryDTO {

    id: Number;
    name: String;
    church_id: Number;
    expense_category_id: Number;
    active: Boolean;

}