<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="kidForm" *ngIf="isEdit">
    <div class="modal-header">
        <h4 class="modal-title">
            Kid Status
        </h4>
        <a class="close" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close noti-icon"></i>
        </a>
    </div>
    <div class="modal-body">
        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Full Name</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{kidDTO?.first_name}}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Alias</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{kidDTO?.alias}}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Date of Birth</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{kidDTO?.date_of_birth  | date : 'mediumDate'}}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Gender</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{kidDTO?.gender }}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Height</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{kidDTO?.height}}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Weight</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{kidDTO?.weight}}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Skin</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{kidDTO?.skin}}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Date Last Seen</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{kidDTO?.date_last_seen  | date : 'mediumDate'}}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Location Last Seen</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{kidDTO?.location_last_seen}}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Circumstances</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{kidDTO?.circumstances}}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Police Station</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{kidDTO?.station?.name}}</label>
            </div>
        </div>


        <div class="row">
            <div class="form-group">
                <label class="control-label">Kid Status</label>
                <ng-select formControlName="status" [items]="statuses" bindLabel="name" placeholder="Select Status" bindValue="id" [ngClass]="{'is-invalid': submitted && f.status.errors,'is-valid': submitted && !f.status.errors}">
                </ng-select>
                <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                    <div *ngIf="f.status.errors.required">This value is required</div>
                    <span *ngIf="f.status.errors?.min">Status is still pending.</span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">Save </ng-container>
            Kid
        </button>
        <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
    </div>
</form>