import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ContributionDTO } from '../dtos/contribution.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class ContributionsService {

  public selectedContribution: ContributionDTO = new ContributionDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/goalContributions/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewByChurch(page: Number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/goalContributions/byChurch/?page=${page}&size=${size}`)
    .pipe(map(data => {
      // request successful log response
      console.log(data);
      return data;
    }));
  }

  viewActive(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/goalContributions/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewContributionItems(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/goalContributions/goalContribution/items/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/goalContributions/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeContributionStatus(contribution_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/goalContributions/${contribution_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateContribution(contribution: ContributionDTO, contribution_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/goalContributions/${contribution_id}`, contribution)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }

  confirmContribution(details: any, contribution_id: number) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + `/goalContributions/status/${contribution_id}/`, details)
    .pipe(map(data => {
      // request successful log response
      console.log(data);
      return data;
    }));

  }


  createContribution(contribution: ContributionDTO){

    console.log(contribution);
    return this.http.post<any>(AppSettings.API_ENDPOINT + `/goalContributions/`, contribution)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }
}
