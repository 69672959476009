import { AccountDTO } from "./account.dto";
import { CurrencyDTO } from "./currency.dto";
import { IncomeCategoryDTO } from "./income_category.dto";
import { PaymentMethodDTO } from "./payment_method.dto";
import { UserDTO } from "./user.dto";

export class IncomeDTO {

    id: Number;
    amount: number;
    reference_date: String;
    reference: String;
    active: Boolean;
    income_id: Number;
    payment_method_id: Number;
    currency_id: Number;
    church_id: Number;
    income_category_id: Number;
    user_id: Number;
    payment_method: PaymentMethodDTO;
    currency: CurrencyDTO;
    income_category: IncomeCategoryDTO;
    user: UserDTO;
    member_id: Number;
    member: UserDTO;
    items: any;
    account: AccountDTO;
    account_id: Number;
    status: number;

}