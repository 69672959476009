import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { User } from '../models/auth.models';
import { AppSettings } from '../helpers/app-settings';
import { Router } from '@angular/router';
import { UserDTO } from '../dtos/user.dto';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public roles: any[] = [];

    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public getCurrentUser(): User {
        return this.currentUserSubject.value;
    }

    resetPassword(email: string) {
        return this.http.post<any>(AppSettings.API_ENDPOINT + '/auth/forgot-password/', {
            email: email
        })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                console.log(user);

                return user;
            }));
    }

    changePassword(resetPasswordToken: string, password: string, confirmPassword: string) {
        console.log(resetPasswordToken, password, confirmPassword)
        return this.http.post<any>(AppSettings.API_ENDPOINT + '/auth/reset-password/', {
            reset_password_token: resetPasswordToken,
            new_password: password,
            confirm_password: confirmPassword,
        })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                console.log(user);

                return user;
            }));
    }


    registerUser(user: UserDTO) {

        console.log(user);
        return this.http.post<any>(AppSettings.API_ENDPOINT + `/users/register/new`, user)
            .pipe(map(user => {
                // request successful log response
                console.log(user);
                if (user && user.access_token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));

    }


    login(username: string, password: string, remember_me: boolean) {

        const params = new HttpParams({
            fromObject: {
                username: username,
                password: password,
                remember_me: remember_me,
            }
        });

        console.log(username, password)
        return this.http.post<any>(AppSettings.API_ENDPOINT + '/auth/login', params)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                console.log(user);
                if (user && user.access_token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    getUser() {

        return this.http.get<any>(AppSettings.API_ENDPOINT + '/users/' + this.getUserId())
            .pipe(map(data => {
                // request successful log response
                console.log("ROles");
                console.log(data);
                return data;
            }));

    }

    updatePassword(new_password: string) {

        return this.http.post<any>(AppSettings.API_ENDPOINT + '/users/passwords/update', {
            'new_password': btoa(btoa(btoa(new_password))),
        }).pipe(map(data => {
            // request successful log response
            console.log(data);
            return data;
        }));
    }

    getUserId() {
        return JSON.parse(localStorage.getItem('currentUser')).userId;
    }

    getLocalCurrentUser() {
        if (JSON.parse(localStorage.getItem('currentUser')) != null) {
            this.roles = JSON.parse(localStorage.getItem('currentUser')).permissions;
            return JSON.parse(localStorage.getItem('currentUser'));
        }
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.clear();
        this.router.navigate(['/account/login']);

        this.currentUserSubject.next(null);
    }

    verifyOtp(otp: string) {

        return this.http.post<any>(AppSettings.API_ENDPOINT + '/users/verify/otp', {
            'otp': otp,
        }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            console.log(user);
            if (user && user.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
            }
            return user;
        }));

    }

    resendOtp() {
        return this.http.post<any>(AppSettings.API_ENDPOINT + '/users/resend/otp', {}).pipe(map(data => {
            // request successful log response
            console.log(data);
            return data;
        }));
    }
}
