import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CityDTO } from 'src/app/core/dtos/city.dto';
import { CitiesService } from 'src/app/core/services/cities.service';
import { CountriesService } from 'src/app/core/services/countries.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-city-modal',
  templateUrl: './city-modal.component.html',
  styleUrls: ['./city-modal.component.scss']
})
export class CityModalComponent implements OnInit {

  entityName: String = 'Cities';
  submitted: boolean;
  cityForm: FormGroup;
  cityDTO: CityDTO = new CityDTO();
  countries:any[] = [];
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private cityService: CitiesService, private countryService: CountriesService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, private utilsService: UtilsService, public formBuilder: FormBuilder) { 
    this.fetchCountries();
  }

  ngOnInit(): void {
    this.cityDTO = this.cityService.selectedCity;
    console.log(this.cityDTO);
    this.isEdit = this.cityService.isEdit;

    this.cityForm = this.formBuilder.group({
      name: [(this.cityDTO ? this.cityDTO.name : ''), Validators.required],
      country_id: [(this.cityDTO ? this.cityDTO?.country?.id : 0), Validators.required],
    })

    this.cityForm.patchValue({ country_id: this.cityDTO?.country?.id });
    
  }

  fetchCountries() {

    this.ngxService.start();
    this.utilsService.getDataWithoutTable(this.countryService.viewActive(), `Fetching Countries`).subscribe(
      data => {
        this.ngxService.stop();
        //set data in service
        this.countries = data;
        //patch the value of select
        // this.addFaqForm.patchValue({ categoryId: this.selectCategories[0]?.id });
      },
      error => {
        this.ngxService.stop();
      });

  }



  get f() {
    return this.cityForm.controls;
  }

  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.cityForm.invalid) {
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): CityDTO {
    var requestDTO = new CityDTO();
    requestDTO.name = this.f.name.value;
    requestDTO.country_id = this.f.country_id.value;
    requestDTO.city_id = this.cityDTO.id;
    requestDTO.active = true;
    return requestDTO;
  }

}
