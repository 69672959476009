<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="contributionForm" *ngIf="isEdit">
    <div class="modal-header">
        <h4 class="modal-title">
            Contribution Status
        </h4>
        <a class="close" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close noti-icon"></i>
        </a>
    </div>
    <div class="modal-body">
        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Goal</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{contributionDTO?.goal?.name}}</label>
            </div>
        </div>
        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Target Amount</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{contributionDTO?.goal?.currency?.short_code+'
                    '+(contributionDTO?.goal?.amount/100 | number : '0.2-2')}}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Contribution Amount</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{contributionDTO?.currency?.short_code+'
                    '+(contributionDTO?.amount/100 | number : '0.2-2')}}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Payment Method</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{contributionDTO?.payment_method?.name}}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Reference</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{contributionDTO?.reference }}</label>
            </div>
        </div>

        <div class="form-group mb-2 row">
            <div class="col-lg-6">
                <label class="control-label">Reference Date</label>

            </div>
            <div class="col-lg-6">
                <label class="control-label">{{contributionDTO?.reference_date | date : 'mediumDate'}}</label>
            </div>
        </div>

        <div class="row">
            <div class="form-group">
                <label for="validationCustom02">Exchange Rate <span
                        style="color: red; font-size: large;">({{contributionDTO?.goal?.currency?.short_code
                        +
                        '&#8644;'
                        +contributionDTO?.currency?.short_code}})</span></label>
                <input type="number" class="form-control" id="validationCustom02" formControlName="exchange_rate" placeholder="Enter Exchange Rate" [ngClass]="{'is-invalid': submitted && f.exchange_rate.errors,'is-valid': submitted && !f.exchange_rate.errors}">
                <div *ngIf="submitted && f.exchange_rate.errors" class="invalid-feedback">
                    <span *ngIf="f.exchange_rate.errors?.required">Please provide a valid exchange rate.</span>
                    <span *ngIf="f.exchange_rate.errors?.min">Exchange Rate cannot be 0 or less.</span>
                </div>
                <div *ngIf="submitted && !f.exchange_rate.errors" class="valid-feedback">
                    <span *ngIf="!f.exchange_rate.errors">Looks good!</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group">
                <label class="control-label">Contribution Status</label>
                <ng-select formControlName="status" [items]="statuses" bindLabel="name" placeholder="Select Status" bindValue="id" [ngClass]="{'is-invalid': submitted && f.status.errors,'is-valid': submitted && !f.status.errors}">
                </ng-select>
                <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                    <div *ngIf="f.status.errors.required">This value is required</div>
                    <span *ngIf="f.status.errors?.min">Status is still pending.</span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">Save </ng-container>
            Contribution
        </button>
        <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
    </div>
</form>