import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IMyDateModel } from 'angular-mydatepicker';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ContributionDTO } from 'src/app/core/dtos/contribution.dto';
import { ContributionsService } from 'src/app/core/services/contributions.service';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { PaymentMethodsService } from 'src/app/core/services/payment-methods.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-contribution-modal',
  templateUrl: './contribution-modal.component.html',
  styleUrls: ['./contribution-modal.component.scss']
})
export class ContributionModalComponent implements OnInit {

  entityName: String = 'Contributions';
  submitted: boolean;
  contributionForm: FormGroup;
  contributionDTO: ContributionDTO = new ContributionDTO();
  currencies: any[] = [];
  payment_methods: any[] = [];
  goals: any[] = [];
  churches: any[] = [];
  members: any[] = [];
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private contributionService: ContributionsService, private currencyService: CurrenciesService, private paymentMethodService: PaymentMethodsService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, public utilsService: UtilsService, public formBuilder: FormBuilder) {
    this.fetchContributionItems();
  }

  ngOnInit(): void {
    this.contributionDTO = this.contributionService.selectedContribution;
    console.log(this.contributionDTO);
    this.isEdit = this.contributionService.isEdit;

    if (this.isEdit) {

      this.contributionForm = this.formBuilder.group({
        amount: [(this.contributionDTO ? (this.contributionDTO.amount/100) : ''), Validators.required],
        reference_date: [(this.contributionDTO ? this.contributionDTO.reference_date : ''), Validators.required],
        reference: [(this.contributionDTO ? this.contributionDTO.reference : ''), Validators.required],
        currency_id: [(this.contributionDTO ? this.contributionDTO?.currency?.id : 0), Validators.required],
        payment_method_id: [(this.contributionDTO ? this.contributionDTO?.payment_method?.id : 0), Validators.required],
        goal_id: [(this.contributionDTO ? this.contributionDTO?.goal?.id : 0), Validators.required],
        member_id: [(this.contributionDTO ? this.contributionDTO?.member_id : 0), Validators.required],
      })

    }else{

      this.contributionForm = this.formBuilder.group({
        amounts: this.formBuilder.array([]),
        reference_date: [(this.contributionDTO ? this.contributionDTO.reference_date : ''), Validators.required],
        reference: [(this.contributionDTO ? this.contributionDTO.reference : ''), Validators.required],
        currency_id: [(this.contributionDTO ? this.contributionDTO?.currency?.id : 0), Validators.required],
        payment_method_id: [(this.contributionDTO ? this.contributionDTO?.payment_method?.id : 0), Validators.required],
        member_id: [(this.contributionDTO ? this.contributionDTO?.member?.id : 0), Validators.required],
      })

    }

    this.contributionForm.patchValue({ currency_id: this.contributionDTO?.currency?.id });
    this.contributionForm.patchValue({ payment_method_id: this.contributionDTO?.payment_method?.id });
    this.contributionForm.patchValue({ goal_id: this.contributionDTO?.goal?.id });
    this.contributionForm.patchValue({ member_id: this.contributionDTO?.member?.id });

    let createDate = new Date(this.contributionDTO.reference_date as string);
    let initDate: IMyDateModel = {
      isRange: false, singleDate: {
        date: {
          year: createDate?.getFullYear(),
          month: createDate?.getMonth() + 1,
          day: createDate?.getDate(),
        }
      }
    };

    this.contributionForm.patchValue({ reference_date: initDate });

  }

  fetchContributionItems() {
    this.ngxService.start();
    this.utilsService.getDataWithoutTable(this.contributionService.viewContributionItems(), `Fetching Currencies`).subscribe(
      data => {
        this.ngxService.stop();
        //set data in service
        this.currencies = data.currencies;
        this.payment_methods = data.payment_methods;
        this.goals = data.goals;
        this.churches = data.churches;
        this.members = data.members;
        //patch the value of select
      },
      error => {
        this.ngxService.stop();
      });
  }

  get f() {
    return this.contributionForm.controls;
  }

  amounts(): FormArray {
    return this.contributionForm.get('amounts') as FormArray
  }

  newAmount(): FormGroup {
    return this.formBuilder.group({
      amount: ['', Validators.required],
      goal_id: [0, Validators.required],
    })
  }

  addAmount(){
    this.amounts().push(this.newAmount())
  }

  removeAmount(i: number){
    this.amounts().removeAt(i);
  }

  close() {
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.contributionForm.invalid) {
      console.log(this.contributionForm)
      console.log("invalid form")
      this.error = 'Please make sure you fill in all the required fields'
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): ContributionDTO {
    var requestDTO = new ContributionDTO();
    requestDTO.items = typeof this.f.amounts == 'undefined' ? '' : this.f.amounts.value;
    requestDTO.amount = typeof this.f.amount == "undefined" ? '' : this.f.amount.value;
    requestDTO.reference_date = this.utilsService.getUpdateDateFromDatePicker(this.f.reference_date.value);
    requestDTO.reference = this.f.reference.value;
    requestDTO.currency_id = this.f.currency_id.value;
    requestDTO.payment_method_id = this.f.payment_method_id.value;
    requestDTO.member_id = this.f.member_id.value;
    requestDTO.goal_contribution_id = this.contributionDTO.id;
    requestDTO.goal_id = typeof this.f.goal_id == "undefined" ? '' : this.f.goal_id.value;
    requestDTO.active = true;
    return requestDTO;
  }


}
