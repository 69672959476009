<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="kidForm">
    <div class="modal-header">
        <h4 class="modal-title">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">New </ng-container>
            Kid
        </h4>
        <a class="close" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close noti-icon"></i>
        </a>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-4" *ngIf="!isEdit">
                <div class="form-group col-12 mb-2">
                    <div class="form-group">
                        <label for="validationCustom02">Image</label>
                        <input type="file" accept="image/*" class="form-control" formControlName="image" (change)="fileChangeEvent($event)" id="validationCustom02">
                        <div *ngIf="submitted && f.image.errors" class="invalid-feedback">
                            <span *ngIf="f.image.errors?.required">Please provide a valid image.</span>
                        </div>
                        <div *ngIf="submitted && !f.image.errors" class="valid-feedback">
                            <span *ngIf="!f.image.errors">Looks good!</span>
                        </div>
                    </div>

                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                    </image-cropper>

                    <img [src]="croppedImage" style="width: 200px;" />
                </div>
            </div>
            <div [ngClass]="isEdit ? 'col-12': 'col-8'">
                <div class="row">
                    <div class="form-group col-6 mb-2">
                        <label for="validationCustom02">First Name</label>
                        <input type="text" class="form-control" id="validationCustom02" formControlName="first_name" placeholder="Enter First Name" [ngClass]="{'is-invalid': submitted && f.first_name.errors,'is-valid': submitted && !f.first_name.errors}">
                        <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                            <span *ngIf="f.first_name.errors?.required">Please provide a valid first name.</span>
                        </div>
                        <div *ngIf="submitted && !f.first_name.errors" class="valid-feedback">
                            <span *ngIf="!f.first_name.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-6 mb-2">
                        <label for="validationCustom02">Last Name</label>
                        <input type="text" class="form-control" id="validationCustom02" formControlName="last_name" placeholder="Enter Last Name" [ngClass]="{'is-invalid': submitted && f.last_name.errors,'is-valid': submitted && !f.last_name.errors}">
                        <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                            <span *ngIf="f.last_name.errors?.required">Please provide a valid last name.</span>
                        </div>
                        <div *ngIf="submitted && !f.last_name.errors" class="valid-feedback">
                            <span *ngIf="!f.last_name.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-6 mb-2">
                        <label for="validationCustom02">Alias</label>
                        <input type="text" class="form-control" id="validationCustom02" formControlName="alias" placeholder="Enter Alias" [ngClass]="{'is-invalid': submitted && f.alias.errors,'is-valid': submitted && !f.alias.errors}">
                        <div *ngIf="submitted && f.alias.errors" class="invalid-feedback">
                            <span *ngIf="f.alias.errors?.required">Please provide a valid alias.</span>
                        </div>
                        <div *ngIf="submitted && !f.alias.errors" class="valid-feedback">
                            <span *ngIf="!f.alias.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-6 mb-2">
                        <label class="control-label">Date of Birth</label>
                        <div class="input-group">
                            <input class="form-control" (click)="dp.toggleCalendar()" placeholder="Select Date of Birth" angular-mydatepicker name="date_of_birth" formControlName="date_of_birth" [options]="utilsService.myOptions" #dp="angular-mydatepicker" [ngClass]="{'is-invalid': submitted && f.date_of_birth.errors, 'is-valid': submitted && !f.date_of_birth.errors}"
                            />
                            <div class="input-group-append">
                                <button type="button" class="btn btn-primary" (click)="dp.toggleCalendar()">
                                    <i class="bx bx-calendar"></i>
                                </button>
                            </div>
                            <div class="input-group-append">
                                <button type="button" class="btn btn-danger" (click)="dp.clearDate()">
                                    <i class="bx bx-x"></i>
                                </button>
                            </div>
                        </div>

                        <div *ngIf="submitted && f.date_of_birth.errors" class="invalid-feedback">
                            <span *ngIf="f.date_of_birth.errors?.required">Please provide a valid date.</span>
                        </div>
                        <div *ngIf="submitted && !f.date_of_birth.errors" class="valid-feedback">
                            <span *ngIf="!f.date_of_birth.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-6 mb-2">
                        <label class="control-label">Gender</label>
                        <ng-select formControlName="gender" [items]="genders" bindLabel="name" placeholder="Select Gender" bindValue="id" [ngClass]="{'is-invalid': submitted && f.gender.errors,'is-valid': submitted && !f.gender.errors}">
                        </ng-select>
                        <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                            <div *ngIf="f.gender.errors.required">This value is required</div>
                            <span *ngIf="f.gender.errors?.min">Gender is still pending.</span>
                        </div>
                    </div>

                    <div class="form-group col-6 mb-2">
                        <label for="validationCustom02">Height (cm)</label>
                        <input type="text" class="form-control" id="validationCustom02" formControlName="height" placeholder="Enter Height" [ngClass]="{'is-invalid': submitted && f.height.errors,'is-valid': submitted && !f.height.errors}">
                        <div *ngIf="submitted && f.height.errors" class="invalid-feedback">
                            <span *ngIf="f.height.errors?.required">Please provide a valid height.</span>
                        </div>
                        <div *ngIf="submitted && !f.height.errors" class="valid-feedback">
                            <span *ngIf="!f.height.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-6 mb-2">
                        <label for="validationCustom02">Weight (kg)</label>
                        <input type="text" class="form-control" id="validationCustom02" formControlName="weight" placeholder="Enter Height" [ngClass]="{'is-invalid': submitted && f.weight.errors,'is-valid': submitted && !f.weight.errors}">
                        <div *ngIf="submitted && f.weight.errors" class="invalid-feedback">
                            <span *ngIf="f.weight.errors?.required">Please provide a valid wegith.</span>
                        </div>
                        <div *ngIf="submitted && !f.weight.errors" class="valid-feedback">
                            <span *ngIf="!f.weight.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-6 mb-2">
                        <label for="validationCustom02">Location last seen</label>
                        <input ngx-google-places-autocomplete [options]="{
                            types: [],
                            componentRestrictions: { country: 'zw' }
                            }" #placesRef="ngx-places" class="form-control" formControlName="location_last_seen" [ngClass]="{'is-invalid': submitted && f.location_last_seen.errors,'is-valid': submitted && !f.location_last_seen.errors}" (onAddressChange)="handleAddressChange($event)"
                        />

                        <!-- <input type="text" class="form-control" id="validationCustom02" formControlName="location_last_seen" placeholder="Enter Location" [ngClass]="{'is-invalid': submitted && f.location_last_seen.errors,'is-valid': submitted && !f.location_last_seen.errors}"> -->
                        <div *ngIf="submitted && f.location_last_seen.errors" class="invalid-feedback">
                            <span *ngIf="f.location_last_seen.errors?.required">Please provide a valid location last
                                seen.</span>
                        </div>
                        <div *ngIf="submitted && !f.location_last_seen.errors" class="valid-feedback">
                            <span *ngIf="!f.location_last_seen.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-6 mb-2">
                        <label class="control-label">Date Last Seen</label>
                        <div class="input-group">
                            <input class="form-control" (click)="dp2.toggleCalendar()" placeholder="Select Date Last Seen" angular-mydatepicker name="date_last_seen" formControlName="date_last_seen" [options]="utilsService.myOptions" #dp2="angular-mydatepicker" [ngClass]="{'is-invalid': submitted && f.date_last_seen.errors, 'is-valid': submitted && !f.date_last_seen.errors}"
                            />
                            <div class="input-group-append">
                                <button type="button" class="btn btn-primary" (click)="dp2.toggleCalendar()">
                                    <i class="bx bx-calendar"></i>
                                </button>
                            </div>
                            <div class="input-group-append">
                                <button type="button" class="btn btn-danger" (click)="dp2.clearDate()">
                                    <i class="bx bx-x"></i>
                                </button>
                            </div>
                        </div>

                        <div *ngIf="submitted && f.date_last_seen.errors" class="invalid-feedback">
                            <span *ngIf="f.date_last_seen.errors?.required">Please provide a valid date.</span>
                        </div>
                        <div *ngIf="submitted && !f.date_last_seen.errors" class="valid-feedback">
                            <span *ngIf="!f.date_last_seen.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-6 mb-2">
                        <label class="control-label">Police Station</label>
                        <ng-select formControlName="station_id" [items]="stations" bindLabel="name" placeholder="Select Station" bindValue="id" [ngClass]="{'is-invalid': submitted && f.station_id.errors,'is-valid': submitted && !f.station_id.errors}">
                        </ng-select>
                        <div *ngIf="submitted && f.station_id.errors" class="invalid-feedback">
                            <div *ngIf="f.station_id.errors.required">This value is required</div>
                        </div>
                    </div>

                    <div class="form-group col-12 mb-2">
                        <label class="control-label">Circumstances</label>
                        <textarea formControlName="circumstances" class="form-control" rows="3" [ngClass]="{'is-invalid': typesubmit && f.circumstances.errors}"></textarea>
                        <div *ngIf="typesubmit && f.circumstances.errors" class="invalid-feedback">
                            <span *ngIf="f.circumstances.errors.required">This value is required.</span>
                        </div>
                    </div>
                </div>

                <hr>

                <h4 *ngIf="!isEdit">Primary Contact</h4>
                <div class="row" *ngIf="!isEdit">
                    <div class="form-group col-4 mb-2">
                        <label for="validationCustom02">Relationship</label>
                        <input type="text" class="form-control" id="validationCustom02" formControlName="contact_label" placeholder="Enter Relationship" [ngClass]="{'is-invalid': submitted && f.contact_label.errors,'is-valid': submitted && !f.contact_label.errors}">
                        <div *ngIf="submitted && f.contact_label.errors" class="invalid-feedback">
                            <span *ngIf="f.contact_label.errors?.required">Please provide a valid relationship.</span>
                        </div>
                        <div *ngIf="submitted && !f.contact_label.errors" class="valid-feedback">
                            <span *ngIf="!f.contact_label.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-4 mb-2">
                        <label for="validationCustom02">Contact First Name</label>
                        <input type="text" class="form-control" id="validationCustom02" formControlName="contact_first_name" placeholder="Enter First Name" [ngClass]="{'is-invalid': submitted && f.contact_first_name.errors,'is-valid': submitted && !f.contact_first_name.errors}">
                        <div *ngIf="submitted && f.contact_first_name.errors" class="invalid-feedback">
                            <span *ngIf="f.contact_first_name.errors?.required">Please provide a valid last name.</span>
                        </div>
                        <div *ngIf="submitted && !f.contact_first_name.errors" class="valid-feedback">
                            <span *ngIf="!f.contact_first_name.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-4 mb-2">
                        <label for="validationCustom02">Contact Last Name</label>
                        <input type="text" class="form-control" id="validationCustom02" formControlName="contact_last_name" placeholder="Enter Last Name" [ngClass]="{'is-invalid': submitted && f.contact_last_name.errors,'is-valid': submitted && !f.contact_last_name.errors}">
                        <div *ngIf="submitted && f.contact_last_name.errors" class="invalid-feedback">
                            <span *ngIf="f.contact_last_name.errors?.required">Please provide a valid last name.</span>
                        </div>
                        <div *ngIf="submitted && !f.contact_last_name.errors" class="valid-feedback">
                            <span *ngIf="!f.contact_last_name.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-6 mb-2">
                        <label for="validationCustom02">Contact Phone Number</label>
                        <ngx-intl-tel-input [cssClass]="'form-control'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Zimbabwe" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="true" [ngClass]="{'is-invalid': submitted && f.contact_primary_phone.errors,'is-valid': submitted && !f.contact_primary_phone.errors}"
                            [numberFormat]="PhoneNumberFormat.National" name="contact_primary_phone" formControlName="contact_primary_phone">
                        </ngx-intl-tel-input>
                        <div *ngIf="submitted && f.contact_primary_phone.errors" class="invalid-feedback">
                            <span *ngIf="f.contact_primary_phone.errors?.required">Please provide a valid phone number.</span>

                            <span *ngIf="f.contact_primary_phone.errors?.validatePhoneNumber">Please provide a valid
                                phone
                                number.</span>
                        </div>
                        <div *ngIf="submitted && !f.contact_primary_phone.errors" class="valid-feedback">
                            <span *ngIf="!f.contact_primary_phone.errors">Looks good!</span>
                        </div>

                    </div>

                    <div class="form-group col-6 mb-2">
                        <label for="validationCustom02">Contact Email</label>
                        <input type="text" class="form-control" id="validationCustom02" formControlName="contact_primary_email" placeholder="Enter Email Address" [ngClass]="{'is-invalid': submitted && f.contact_primary_email.errors,'is-valid': submitted && !f.contact_primary_email.errors}">
                        <div *ngIf="submitted && f.contact_primary_email.errors" class="invalid-feedback">
                            <span *ngIf="f.contact_primary_email.errors?.required">Please provide a valid email.</span>
                        </div>
                        <div *ngIf="submitted && !f.contact_primary_email.errors" class="valid-feedback">
                            <span *ngIf="!f.contact_primary_email.errors">Looks good!</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">Save </ng-container>
            Kid
        </button>
        <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
    </div>
</form>