import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationDTO } from '../dtos/organisation.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class OrganisationsService {

  public selectedOrganisation: OrganisationDTO = new OrganisationDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/organisations/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive() {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/organisations/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/organisations/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeOrganisationStatus(organisatione_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/organisations/${organisatione_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateOrganisation(organisation: OrganisationDTO, organisatione_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/organisations/${organisatione_id}`, organisation)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createOrganisation(organisation: OrganisationDTO) {

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/organisations/`, organisation)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  searchOrganisationsWithObservable(query): Observable<any[]> {
    return this.http.post<any>(AppSettings.API_ENDPOINT + `/organisations/searchByName?query=${query}&page=1&size=50`, query)
      .pipe(map(data => data.items));
    // return of(result).pipe(delay(500));;
  }
}
