import { OrganisationDTO } from "./organisation.dto";
import { CurrencyDTO } from "./currency.dto";
import { GoalDTO } from "./goal.dto";
import { PaymentMethodDTO } from "./payment_method.dto";
import { UserDTO } from "./user.dto";

export class ContributionDTO {
    id: Number;
    amount: number;
    reference_date: String;
    reference: String;
    active: Boolean;
    income_id: Number;
    payment_method_id: Number;
    currency_id: Number;
    organisation_id: Number;
    user_id: Number;
    payment_method: PaymentMethodDTO;
    currency: CurrencyDTO;
    organisation: OrganisationDTO;
    user: UserDTO;
    member_id: Number;
    member: UserDTO;
    items: any;
    goal: GoalDTO;
    goal_id: number;
    goal_contribution_id: Number;
    status: Number;
    exchange_rate: Number;

}