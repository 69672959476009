<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="countryForm">
    <div class="modal-header">
        <h4 class="modal-title">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">New </ng-container>
            Country
        </h4>
        <a class="close" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close noti-icon"></i>
        </a>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="form-group">
                <label for="validationCustom02">Country Name</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="name" placeholder="Enter Country Name" [ngClass]="{'is-invalid': submitted && f.name.errors,'is-valid': submitted && !f.name.errors}">
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <span *ngIf="f.name.errors?.required">Please provide a valid country name.</span>
                </div>
                <div *ngIf="submitted && !f.name.errors" class="valid-feedback">
                    <span *ngIf="!f.name.errors">Looks good!</span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">Save </ng-container>
            Country
        </button>
        <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
    </div>
</form>