import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PaymentMethodDTO } from '../dtos/payment_method.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {

  public selectedPaymentMethod: PaymentMethodDTO = new PaymentMethodDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/paymentMethods/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/paymentMethods/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/paymentMethods/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changePaymentMethodStatus(payment_method_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/paymentMethods/${payment_method_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updatePaymentMethod(payment_method: PaymentMethodDTO, payment_method_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/paymentMethods/${payment_method_id}`, payment_method)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createPaymentMethod(payment_method: PaymentMethodDTO){

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/paymentMethods/`, payment_method)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

}
