import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { KidDTO } from 'src/app/core/dtos/kid.dto';
import { KidService } from 'src/app/core/services/kid.service';
import { PaymentMethodsService } from 'src/app/core/services/payment-methods.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-kid-confirm-modal',
  templateUrl: './kid-confirm-modal.component.html',
  styleUrls: ['./kid-confirm-modal.component.scss']
})
export class KidConfirmModalComponent implements OnInit {

  entityName: String = 'Kids';
  submitted: boolean;
  kidForm: FormGroup;
  kidDTO: KidDTO = new KidDTO();
  statuses: any[] = [];
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private kidService: KidService, public utilsService: UtilsService, public formBuilder: FormBuilder) {


    this.statuses = [
      {
        id: 'MISSING',
        name: 'MISSING',
      },
      {
        id: 'FOUND',
        name: 'FOUND'
      },
      {
        id: 'UNKNOWN',
        name: 'UNKNOWN'
      }
    ]
  }

  ngOnInit(): void {
    this.kidDTO = this.kidService.selectedKid;
    console.log(this.kidDTO);
    this.isEdit = this.kidService.isEdit;
    {

      this.kidForm = this.formBuilder.group({
        status: [(this.kidDTO ? this.kidDTO?.status : 'MISSING'), (Validators.required)],
      })



    }

  }


  get f() {
    return this.kidForm.controls;
  }

  close() {
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.kidForm.invalid) {
      console.log(this.kidForm)
      console.log("invalid form")
      this.error = 'Please make sure you fill in all the required fields'
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): KidDTO {
    var requestDTO = new KidDTO();
    requestDTO.status = this.f.status.value;
    // requestDTO.active = true;
    return requestDTO;
  }
}
