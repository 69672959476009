import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CountryDTO } from 'src/app/core/dtos/country.dto';
import { CountriesService } from 'src/app/core/services/countries.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-country-modal',
  templateUrl: './country-modal.component.html',
  styleUrls: ['./country-modal.component.scss']
})
export class CountryModalComponent implements OnInit {

  entityName: String = 'Countries';
  submitted: boolean;
  countryForm: FormGroup;
  countryDTO: CountryDTO = new CountryDTO();
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private countryService: CountriesService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, private utilsService: UtilsService, public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.countryDTO = this.countryService.selectedCountry;
    this.isEdit = this.countryService.isEdit;

    this.countryForm = this.formBuilder.group({
      name: [(this.countryDTO ? this.countryDTO.name : ''), Validators.required],
    })
    
  }


  get f() {
    return this.countryForm.controls;
  }

  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.countryForm.invalid) {
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): CountryDTO {
    var requestDTO = new CountryDTO();
    requestDTO.name = this.f.name.value;
    requestDTO.country_id = this.countryDTO.id;
    requestDTO.active = true;
    return requestDTO;
  }


}
