import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IMyDateModel } from 'angular-mydatepicker';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ExpenseDTO } from 'src/app/core/dtos/expense.dto';
import { OrganisationsService } from 'src/app/core/services/organisations.service';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { ExpenseCategoriesService } from 'src/app/core/services/expense-categories.service';
import { ExpensesService } from 'src/app/core/services/expenses.service';
import { PaymentMethodsService } from 'src/app/core/services/payment-methods.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-expense-modal',
  templateUrl: './expense-modal.component.html',
  styleUrls: ['./expense-modal.component.scss']
})
export class ExpenseModalComponent implements OnInit {

  entityName: String = 'Expenses';
  submitted: boolean;
  expenseForm: FormGroup;
  expenseDTO: ExpenseDTO = new ExpenseDTO();
  currencies:any[] = [];
  payment_methods:any[] = [];
  expense_categories:any[] = [];
  churches:any[] = [];
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private expenseService: ExpensesService, private currencyService: CurrenciesService, private paymentMethodService: PaymentMethodsService, private expenseCategoryService: ExpenseCategoriesService, private organisationService: OrganisationsService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, public utilsService: UtilsService, public formBuilder: FormBuilder) { 
    this.fetchExpenseItems();
  }

  ngOnInit(): void {
    this.expenseDTO = this.expenseService.selectedExpense;
    console.log(this.expenseDTO);
    this.isEdit = this.expenseService.isEdit;

    this.expenseForm = this.formBuilder.group({
      amount: [(this.expenseDTO ? this.expenseDTO.amount : ''), Validators.required],
      reference_date: [(this.expenseDTO ? this.expenseDTO.reference_date : ''), Validators.required],
      reference: [(this.expenseDTO ? this.expenseDTO.reference : ''), Validators.required],
      currency_id: [(this.expenseDTO ? this.expenseDTO?.currency?.id : 0), Validators.required],
      payment_method_id: [(this.expenseDTO ? this.expenseDTO?.payment_method?.id : 0), Validators.required],
      expense_category_id: [(this.expenseDTO ? this.expenseDTO?.expense_category?.id : 0), Validators.required],
    })

    this.expenseForm.patchValue({ currency_id: this.expenseDTO?.currency?.id });
    this.expenseForm.patchValue({ payment_method_id: this.expenseDTO?.payment_method?.id });
    this.expenseForm.patchValue({ expense_category_id: this.expenseDTO?.expense_category?.id });

    let createDate = new Date(this.expenseDTO.reference_date as string);
    let initDate: IMyDateModel = {
      isRange: false, singleDate: {
        date: {
          year: createDate?.getFullYear(),
          month: createDate?.getMonth() + 1,
          day: createDate?.getDate()
        }
      }
    };
    
    this.expenseForm.patchValue({ reference_date: initDate });

  }

  fetchExpenseItems() {
    this.ngxService.start();
    this.utilsService.getDataWithoutTable(this.expenseService.viewExpenseItems(), `Fetching Currencies`).subscribe(
      data => {
        this.ngxService.stop();
        //set data in service
        this.currencies = data.currencies;
        this.payment_methods = data.payment_methods;
        this.expense_categories = data.expense_categories;
        this.churches = data.churches;
        //patch the value of select
      },
      error => {
        this.ngxService.stop();
      });
  }



  get f() {
    return this.expenseForm.controls;
  }

  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.expenseForm.invalid) {
      console.log("invalid form")
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): ExpenseDTO {
    var requestDTO = new ExpenseDTO();
    requestDTO.amount = this.f.amount.value;
    requestDTO.reference_date = this.utilsService.getUpdateDateFromDatePicker(this.f.reference_date.value);
    requestDTO.reference = this.f.reference.value;
    requestDTO.currency_id = this.f.currency_id.value;
    requestDTO.payment_method_id = this.f.payment_method_id.value;
    requestDTO.expense_category_id = this.f.expense_category_id.value;
    requestDTO.expense_id = this.expenseDTO.id;
    requestDTO.active = true;
    return requestDTO;
  }

}
