import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { KidDTO } from 'src/app/core/dtos/kid.dto';
import { StationDTO } from 'src/app/core/dtos/station.dto';
import { AppSettings } from 'src/app/core/helpers/app-settings';
import { KidService } from 'src/app/core/services/kid.service';
import { StationsService } from 'src/app/core/services/stations.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-views-modal',
  templateUrl: './views-modal.component.html',
  styleUrls: ['./views-modal.component.scss']
})
export class ViewsModalComponent implements OnInit {

  kids: any = []
  searchTerm: string = '';
  error = '';
  hidekid: boolean[] = [];
  stationDTO: StationDTO = new StationDTO();
  totalRecords: number;
  isSearchResults: boolean = false;


  kidsPicUrl = AppSettings.API_ENDPOINT + "/kids/download/file/path/";

  constructor(private activeModal: NgbActiveModal, private stationService: StationsService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, private utilsService: UtilsService, public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.stationDTO = this.stationService.selectedStation;
    this.kids = this.stationDTO.kids;

    for (let i = 0; i <= this.kids.length; i++) {
      this.hidekid.push(true);
    }
  }

  setRecords() {

    setTimeout(() => {
      for (let i = 0; i <= this.totalRecords; i++) {
        this.hidekid.push(true);
      }
    }, 3000)

  }

  changeKidValue(i) {
    this.hidekid[i] = !this.hidekid[i];
  }

  close() {
    this.activeModal.close();
  }

}
