import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ExpenseCategoryDTO } from 'src/app/core/dtos/expense_category.dto';
import { OrganisationsService } from 'src/app/core/services/organisations.service';
import { ExpenseCategoriesService } from 'src/app/core/services/expense-categories.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-expense-category-modal',
  templateUrl: './expense-category-modal.component.html',
  styleUrls: ['./expense-category-modal.component.scss']
})
export class ExpenseCategoryModalComponent implements OnInit {

  entityName: String = 'Expense Categories';
  submitted: boolean;
  expenseCategoryForm: FormGroup;
  expenseCategoryDTO: ExpenseCategoryDTO = new ExpenseCategoryDTO();
  churches:any[] = [];
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private expenseCategoryService: ExpenseCategoriesService, private OrganisationsService: OrganisationsService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, private utilsService: UtilsService, public formBuilder: FormBuilder) { 
    
  }

  ngOnInit(): void {
    this.expenseCategoryDTO = this.expenseCategoryService.selectedExpenseCategory;
    console.log(this.expenseCategoryDTO);
    this.isEdit = this.expenseCategoryService.isEdit;

    this.expenseCategoryForm = this.formBuilder.group({
      name: [(this.expenseCategoryDTO ? this.expenseCategoryDTO.name : ''), Validators.required],
    })

    
  }


  get f() {
    return this.expenseCategoryForm.controls;
  }

  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.expenseCategoryForm.invalid) {
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): ExpenseCategoryDTO {
    var requestDTO = new ExpenseCategoryDTO();
    requestDTO.name = this.f.name.value;
    requestDTO.expense_category_id = this.expenseCategoryDTO.id;
    requestDTO.active = true;
    return requestDTO;
  }

}
