<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="conferenceForm">
    <div class="modal-header">
        <h4 class="modal-title">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">New </ng-container>
            Conference
        </h4>
        <a class="close" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close noti-icon"></i>
        </a>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="form-group mb-2">
                <label for="validationCustom02">Conference Name</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="name" placeholder="Enter Conference Name" [ngClass]="{'is-invalid': submitted && f.name.errors,'is-valid': submitted && !f.name.errors}">
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <span *ngIf="f.name.errors?.required">Please provide a valid conference name.</span>
                </div>
                <div *ngIf="submitted && !f.name.errors" class="valid-feedback">
                    <span *ngIf="!f.name.errors">Looks good!</span>
                </div>
            </div>
            <div class="form-group mb-2">
                <label for="validationCustom02">Conference Address</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="address" placeholder="Enter Conference Address" [ngClass]="{'is-invalid': submitted && f.address.errors,'is-valid': submitted && !f.address.errors}">
                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                    <span *ngIf="f.address.errors?.required">Please provide a valid conference address.</span>
                </div>
                <div *ngIf="submitted && !f.address.errors" class="valid-feedback">
                    <span *ngIf="!f.address.errors">Looks good!</span>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">Station</label>
                <ng-select formControlName="station_id" [items]="stations" bindLabel="name" placeholder="Select Station" bindValue="id" [ngClass]="{'is-invalid': submitted && f.station_id.errors,'is-valid': submitted && !f.station_id.errors}">
                </ng-select>
                <div *ngIf="submitted && f.station_id.errors" class="invalid-feedback">
                    <div *ngIf="f.station_id.errors.required">This value is required</div>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">Save </ng-container>
            Conference
        </button>
        <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
    </div>
</form>