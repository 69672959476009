import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ContactDTO } from '../dtos/contact.dto';
import { StationDTO } from '../dtos/station.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class StationsService {

  public selectedStation: StationDTO = new StationDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/stations/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive() {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/stations/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/stations/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeStationStatus(station_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/stations/${station_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateStation(station: StationDTO, station_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/stations/${station_id}`, station)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createStation(station: StationDTO) {

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/stations/`, station)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  createContact(contact: ContactDTO, station_id) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + `/stations/${station_id}/contact/`, contact)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }
}
