import { CityDTO } from "./city.dto";
import { ConferenceDTO } from "./conference.dto";
import { CountryDTO } from "./country.dto";

export class OrganisationDTO {

    id: Number;
    name: String;
    address: String;
    country_id: Number;
    active: Boolean;
    organisation_id: Number;
    country: CountryDTO;

}