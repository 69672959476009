import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IncomeCategoryDTO } from '../dtos/income_category.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class IncomeCategoriesService {

  public selectedIncomeCategory: IncomeCategoryDTO = new IncomeCategoryDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/incomeCategories/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }


  viewByChurch(page: number, size: number){
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/incomeCategories/byChurch/?page=${page}&size=${size}`)
    .pipe(map(data => {
      // request successful log response
      console.log(data);
      return data;
    }));
  }

  viewActive(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/incomeCategories/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/incomeCategories/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeIncomeCategoryStatus(income_category_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/incomeCategories/${income_category_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateIncomeCategory(income_category: IncomeCategoryDTO, income_category_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/incomeCategories/${income_category_id}`, income_category)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createIncomeCategory(income_category: IncomeCategoryDTO){

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/incomeCategories/`, income_category)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }
  
}
