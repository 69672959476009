import { CurrencyDTO } from "./currency.dto";
import { ExpenseCategoryDTO } from "./expense_category.dto";
import { PaymentMethodDTO } from "./payment_method.dto";
import { UserDTO } from "./user.dto";

export class ExpenseDTO {

    id: Number;
    amount: String;
    reference_date: String;
    reference: String;
    active: Boolean;
    expense_id: Number;
    payment_method_id: Number;
    currency_id: Number;
    church_id: Number;
    expense_category_id: Number;
    user_id: Number;
    payment_method: PaymentMethodDTO;
    currency: CurrencyDTO;
    expense_category: ExpenseCategoryDTO;
    user: UserDTO;

}