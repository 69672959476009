import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConferenceDTO } from 'src/app/core/dtos/conference.dto';
import { ConferencesService } from 'src/app/core/services/conferences.service';
import { StationsService } from 'src/app/core/services/stations.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-conference-modal',
  templateUrl: './conference-modal.component.html',
  styleUrls: ['./conference-modal.component.scss']
})
export class ConferenceModalComponent implements OnInit {

  entityName: String = 'Conferences';
  submitted: boolean;
  conferenceForm: FormGroup;
  conferenceDTO: ConferenceDTO = new ConferenceDTO();
  stations: any[] = [];
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private stationService: StationsService, private conferenceService: ConferencesService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, private utilsService: UtilsService, public formBuilder: FormBuilder) {
    this.fetchStations();
  }

  ngOnInit(): void {
    this.conferenceDTO = this.conferenceService.selectedConference;
    console.log(this.conferenceDTO);
    this.isEdit = this.conferenceService.isEdit;

    this.conferenceForm = this.formBuilder.group({
      name: [(this.conferenceDTO ? this.conferenceDTO.name : ''), Validators.required],
      address: [(this.conferenceDTO ? this.conferenceDTO.address : ''), Validators.required],
      station_id: [(this.conferenceDTO ? this.conferenceDTO?.station?.id : 0), Validators.required],
    })

    this.conferenceForm.patchValue({ station_id: this.conferenceDTO?.station?.id });
    
  }

  fetchStations() {

    this.ngxService.start();
    this.utilsService.getDataWithoutTable(this.stationService.viewActive(), `${this.entityName}`).subscribe(
      data => {
        this.ngxService.stop();
        //set data in service
        this.stations = data;
        //patch the value of select
        // this.addFaqForm.patchValue({ categoryId: this.selectCategories[0]?.id });
      },
      error => {
        this.ngxService.stop();
      });

  }



  get f() {
    return this.conferenceForm.controls;
  }

  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.conferenceForm.invalid) {
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): ConferenceDTO {
    var requestDTO = new ConferenceDTO();
    requestDTO.name = this.f.name.value;
    requestDTO.address = this.f.address.value;
    requestDTO.station_id = this.f.station_id.value;
    requestDTO.conference_id = this.conferenceDTO.id;
    requestDTO.active = true;
    return requestDTO;
  }

}
