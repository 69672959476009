import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ContactDTO } from 'src/app/core/dtos/contact.dto';
import { ContactsService } from 'src/app/core/services/contacts.service';
import { KidService } from 'src/app/core/services/kid.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss']
})
export class ContactModalComponent implements OnInit {

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Zimbabwe, CountryISO.SouthAfrica];


  submitted: boolean;
  contactForm: FormGroup;
  contactDTO: ContactDTO = new ContactDTO();
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private contactService: ContactsService, private kidService: KidService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, public utilsService: UtilsService, public formBuilder: FormBuilder) {

  }

  ngOnInit(): void {

    this.contactDTO = this.contactService.selectedContact;
    this.isEdit = this.contactService.isEdit;

    if (this.isEdit) {

      this.contactForm = this.formBuilder.group({
        first_name: [(this.contactDTO ? (this.contactDTO.first_name) : ''), Validators.required],
        last_name: [(this.contactDTO ? (this.contactDTO.last_name) : ''), Validators.required],
        primary_phone: [(this.contactDTO ? (this.contactDTO.primary_phone) : ''), Validators.required],
        primary_email: [(this.contactDTO ? (this.contactDTO.primary_email) : ''), Validators.required],
        label: [(this.contactDTO ? (this.contactDTO.label) : ''), Validators.required]
      })

    } else {

      this.contactForm = this.formBuilder.group({
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        primary_phone: ['', Validators.required],
        primary_email: ['', Validators.required],
        label: ['', Validators.required]
      })
    }
  }

  get f() {
    return this.contactForm.controls;
  }

  close() {
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) {
      console.log(this.contactForm)
      console.log("invalid form")
      this.error = 'Please make sure you fill in all the required fields'
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): ContactDTO {
    var requestDTO = new ContactDTO();
    requestDTO.first_name = this.f.first_name.value;
    requestDTO.last_name = this.f.last_name.value;
    requestDTO.label = this.f.label.value;
    requestDTO.primary_email = this.f.primary_email.value;
    requestDTO.primary_phone = this.utilsService.getPhonenumberAsString(this.f.primary_phone.value);
    return requestDTO;
  }

}
