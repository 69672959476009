import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { KidDTO } from 'src/app/core/dtos/kid.dto';
import { AppSettings } from 'src/app/core/helpers/app-settings';
import { KidService } from 'src/app/core/services/kid.service';
import { StationsService } from 'src/app/core/services/stations.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-kid-image-modal',
  templateUrl: './kid-image-modal.component.html',
  styleUrls: ['./kid-image-modal.component.scss']
})
export class KidImageModalComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageToSend: any;
  fileName: any = '';
  defaultAlreadyExists: boolean;
  showCropper = false;

  entityName: String = 'Kids';
  submitted: boolean;
  kidForm: FormGroup;
  kidDTO: KidDTO = new KidDTO();
  isEdit = false;
  error = '';


  kidsPicUrl = AppSettings.API_ENDPOINT + "/kids/download/file/path/";

  constructor(private activeModal: NgbActiveModal, private kidService: KidService, private stationService: StationsService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, public utilsService: UtilsService, public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.kidDTO = this.kidService.selectedKid;
    if (this.kidDTO?.kid_attachments?.length != 0) {
      this.kidsPicUrl = this.kidsPicUrl + this.kidDTO?.kid_attachments[0]?.attachment?.id;
    } else {
      this.kidsPicUrl = '/assets/images/placeholder-image.png'
    }
    this.kidForm = this.formBuilder.group({
      image: [''],
    });
  }

  get f() {
    return this.kidForm.controls;
  }

  close() {
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.kidForm.invalid) {
      console.log(this.kidForm)
      console.log("invalid form")
      this.error = 'Please make sure you fill in all the required fields'
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): KidDTO {
    var requestDTO = new KidDTO();
    requestDTO.file = this.dataURLtoFile(this.croppedImage, this.fileName);
    return requestDTO;
  }

  fileChangeEvent(event: any): void {
    // this.imageChangedEvent = event;

    if (event.target.files && event.target.files[0]) {

      this.fileName = event.target.files[0].name;

      if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpg') {

        if (event.target.files[0].size > 10000000) {
          // checking size here - 2MB}
          console.log("Image Too Big")
          this.f['image'].setValue(null);
          this.imageChangedEvent = null;
          this.croppedImage = null;
          this.toastr.error('Image is too big.');
          return;
        }
        this.imageChangedEvent = event;

      } else {
        console.log("type is not image")
        this.f['image'].setValue(null);
        this.imageChangedEvent = null;
        this.croppedImage = null;
        this.toastr.error('File selected is not an image');
      }
    } else {
      console.log("Nothing selected")
      // this.toastr.error('Nothing selected');
    }

  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imageToSend = event;
    console.log(event);
    // let file:File = event.target.files[0];
  }

  imageLoaded() {
    // show cropper
    // this.showCropper = true;
    console.log("Image loaded");
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  addImage() {

    console.log('this is the cropped image');
    // console.log(this.croppedImage);
    this.submitted = true;
    // stop here if form is invalid
    if (this.kidForm.invalid) {
      console.log(this.kidForm);
      return;
    } else {
      //add to list
      //Pass value
      if (this.f.default.value === true && this.defaultAlreadyExists === true) {
        this.toastr.error('A default product variation already exists');
        return;
      }
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());

    }

  }

  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

}
