import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IMyDateModel } from 'angular-mydatepicker';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ContributionDTO } from 'src/app/core/dtos/contribution.dto';
import { ContributionsService } from 'src/app/core/services/contributions.service';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { PaymentMethodsService } from 'src/app/core/services/payment-methods.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-contribution-confirm-modal',
  templateUrl: './contribution-confirm-modal.component.html',
  styleUrls: ['./contribution-confirm-modal.component.scss']
})
export class ContributionConfirmModalComponent implements OnInit {

  entityName: String = 'Contributions';
  submitted: boolean;
  contributionForm: FormGroup;
  contributionDTO: ContributionDTO = new ContributionDTO();
  statuses: any[] = [];
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private contributionService: ContributionsService, private currencyService: CurrenciesService, private paymentMethodService: PaymentMethodsService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, public utilsService: UtilsService, public formBuilder: FormBuilder) {


    this.statuses = [
      {
        id: 1,
        name: 'PENDING',
      },
      {
        id: 2,
        name: 'ACCEPT'
      }
    ]
  }

  ngOnInit(): void {
    this.contributionDTO = this.contributionService.selectedContribution;
    console.log(this.contributionDTO);
    this.isEdit = this.contributionService.isEdit;
{

      this.contributionForm = this.formBuilder.group({
        exchange_rate: [0, (Validators.required, Validators.min(1))],
        status: [(this.contributionDTO ? this.contributionDTO?.status : 1), (Validators.required)],
      })

      


  }

}


  get f() {
    return this.contributionForm.controls;
  }

  close() {
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.contributionForm.invalid) {
      console.log(this.contributionForm)
      console.log("invalid form")
      this.error = 'Please make sure you fill in all the required fields'
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): ContributionDTO {
    var requestDTO = new ContributionDTO();
    requestDTO.status = this.f.status.value;
    requestDTO.exchange_rate = this.f.exchange_rate.value;
    // requestDTO.active = true;
    return requestDTO;
  }

}
