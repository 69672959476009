<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="accountForm">
    <div class="modal-header">
        <h4 class="modal-title">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">New </ng-container>
            Account
        </h4>
        <a class="close" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close noti-icon"></i>
        </a>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="form-group mb-2">
                <label for="validationCustom02">Account Name</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="account_name" placeholder="Enter Account Name" [ngClass]="{'is-invalid': submitted && f.account_name.errors,'is-valid': submitted && !f.account_name.errors}">
                <div *ngIf="submitted && f.account_name.errors" class="invalid-feedback">
                    <span *ngIf="f.account_name.errors?.required">Please provide a valid account name.</span>
                </div>
                <div *ngIf="submitted && !f.account_name.errors" class="valid-feedback">
                    <span *ngIf="!f.account_name.errors">Looks good!</span>
                </div>
            </div>
            <div class="form-group mb-2">
                <label for="validationCustom02">Account Number</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="account_number" placeholder="Enter Account Number" [ngClass]="{'is-invalid': submitted && f.account_number.errors,'is-valid': submitted && !f.account_number.errors}">
                <div *ngIf="submitted && f.account_number.errors" class="invalid-feedback">
                    <span *ngIf="f.account_number.errors?.required">Please provide a valid account number.</span>
                </div>
                <div *ngIf="submitted && !f.account_number.errors" class="valid-feedback">
                    <span *ngIf="!f.account_number.errors">Looks good!</span>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="validationCustom02">Branch</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="branch" placeholder="Enter Branch Name" [ngClass]="{'is-invalid': submitted && f.branch.errors,'is-valid': submitted && !f.branch.errors}">
                <div *ngIf="submitted && f.branch.errors" class="invalid-feedback">
                    <span *ngIf="f.branch.errors?.required">Please provide a valid branch.</span>
                </div>
                <div *ngIf="submitted && !f.branch.errors" class="valid-feedback">
                    <span *ngIf="!f.branch.errors">Looks good!</span>
                </div>
            </div>

            <div class="form-group mb-2">
                <label class="control-label">Church</label>
                <ng-select formControlName="church_id" [items]="churches" bindLabel="name" placeholder="Select Church" bindValue="id" [ngClass]="{'is-invalid': submitted && f.church_id.errors,'is-valid': submitted && !f.church_id.errors}">
                </ng-select>
                <div *ngIf="submitted && f.church_id.errors" class="invalid-feedback">
                    <div *ngIf="f.church_id.errors.required">This value is required</div>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">Payment Method</label>
                <ng-select formControlName="payment_method_id" [items]="payment_methods" bindLabel="name" placeholder="Select Payment Method" bindValue="id" [ngClass]="{'is-invalid': submitted && f.payment_method_id.errors,'is-valid': submitted && !f.payment_method_id.errors}">
                </ng-select>
                <div *ngIf="submitted && f.payment_method_id.errors" class="invalid-feedback">
                    <div *ngIf="f.payment_method_id.errors.required">This value is required</div>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">Save </ng-container>
            Account
        </button>
        <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
    </div>
</form>