import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CityDTO } from '../dtos/city.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  public selectedCity: CityDTO = new CityDTO();
  public isEdit = false;
  error = '';

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/cities/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/cities/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/cities/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeCityStatus(city_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/cities/${city_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateCity(city: CityDTO, city_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/cities/${city_id}`, city)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createCity(city: CityDTO){

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/cities/`, city)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }
}
