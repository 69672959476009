import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppropriationDTO } from '../dtos/appropriation.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class AppropriationsService {

  public selectedAppropriation: AppropriationDTO = new AppropriationDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/appropriations/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  viewActive(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/appropriations/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/appropriations/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeAppropriationStatus(appropriation_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/appropriations/${appropriation_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateAppropriation(appropriation: AppropriationDTO, appropriation_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/appropriations/${appropriation_id}`, appropriation)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createAppropriation(appropriation: AppropriationDTO){

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/appropriations/`, appropriation)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }
}
