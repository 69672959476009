import { ContactDTO } from "./contact.dto";
import { CountryDTO } from "./country.dto";
import { KidDTO } from "./kid.dto";

export class StationDTO {

    id: Number;
    name: String;
    address: String;
    country_id: Number;
    station_id: Number;
    country: CountryDTO;
    active: Boolean;
    contacts: ContactDTO[]
    kids: KidDTO[]

}