<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="expenseForm">
    <div class="modal-header">
        <h4 class="modal-title">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">New </ng-container>
            Expense
        </h4>
        <a class="close" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close noti-icon"></i>
        </a>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="form-group mb-2">
                <label class="control-label">Expense Category</label>
                <ng-select formControlName="expense_category_id" [items]="expense_categories" placeholder="Select Expense Category" bindLabel="name" bindValue="id" [ngClass]="{'is-invalid': submitted && f.expense_category_id.errors,'is-valid': submitted && !f.expense_category_id.errors}">
                </ng-select>
                <div *ngIf="submitted && f.expense_category_id.errors" class="invalid-feedback">
                    <div *ngIf="f.expense_category_id.errors.required">This value is required</div>
                </div>
            </div>
            <div class="form-group mb-2">
                <label for="validationCustom02">Amount</label>
                <input type="number" class="form-control" id="validationCustom02" formControlName="amount" placeholder="Enter Amount" [ngClass]="{'is-invalid': submitted && f.amount.errors,'is-valid': submitted && !f.amount.errors}">
                <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                    <span *ngIf="f.amount.errors?.required">Please provide a valid amount.</span>
                </div>
                <div *ngIf="submitted && !f.amount.errors" class="valid-feedback">
                    <span *ngIf="!f.amount.errors">Looks good!</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-6 mb-2">
                <label class="control-label">Currency</label>
                <ng-select formControlName="currency_id" [items]="currencies" placeholder="Select Currency" bindLabel="name" bindValue="id" [ngClass]="{'is-invalid': submitted && f.currency_id.errors,'is-valid': submitted && !f.currency_id.errors}">
                </ng-select>
                <div *ngIf="submitted && f.currency_id.errors" class="invalid-feedback">
                    <div *ngIf="f.currency_id.errors.required">This value is required</div>
                </div>
            </div>
            <div class="form-group col-6 mb-2">
                <label class="control-label">Payment Method</label>
                <ng-select formControlName="payment_method_id" [items]="payment_methods" placeholder="Select Payment Method" bindLabel="name" bindValue="id" [ngClass]="{'is-invalid': submitted && f.payment_method_id.errors,'is-valid': submitted && !f.payment_method_id.errors}">
                </ng-select>
                <div *ngIf="submitted && f.payment_method_id.errors" class="invalid-feedback">
                    <div *ngIf="f.payment_method_id.errors.required">This value is required</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-6 mb-2">
                <label class="control-label">Reference</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="reference" placeholder="Enter Reference" [ngClass]="{'is-invalid': submitted && f.reference.errors,'is-valid': submitted && !f.reference.errors}">
                <div *ngIf="submitted && f.reference.errors" class="invalid-feedback">
                    <span *ngIf="f.reference.errors?.required">Please provide a valid reference.</span>
                </div>
                <div *ngIf="submitted && !f.reference.errors" class="valid-feedback">
                    <span *ngIf="!f.reference.errors">Looks good!</span>
                </div>
            </div>
            <div class="form-group col-6 mb-2">
                <label class="control-label">Reference Date</label>

                <div class="input-group">
                    <input class="form-control" (click)="dp.toggleCalendar()" placeholder="Select Date" angular-mydatepicker name="reference_date" formControlName="reference_date" [options]="utilsService.myOptions" #dp="angular-mydatepicker" [ngClass]="{'is-invalid': submitted && f.reference_date.errors, 'is-valid': submitted && !f.reference_date.errors}"
                    />
                    <div class="input-group-append">
                        <button type="button" class="btn btn-primary" (click)="dp.toggleCalendar()">
                            <i class="bx bx-calendar"></i>
                        </button>
                    </div>
                    <div class="input-group-append">
                        <button type="button" class="btn btn-danger" (click)="dp.clearDate()">
                            <i class="bx bx-x"></i>
                        </button>
                    </div>
                </div>

                <div *ngIf="submitted && f.reference_date.errors" class="invalid-feedback">
                    <span *ngIf="f.reference_date.errors?.required">Please provide a valid reference date.</span>
                </div>
                <div *ngIf="submitted && !f.reference_date.errors" class="valid-feedback">
                    <span *ngIf="!f.reference_date.errors">Looks good!</span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">Save </ng-container>
            Expense
        </button>
        <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
    </div>
</form>