import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CurrencyDTO } from 'src/app/core/dtos/currency.dto';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-currency-modal',
  templateUrl: './currency-modal.component.html',
  styleUrls: ['./currency-modal.component.scss']
})
export class CurrencyModalComponent implements OnInit {

  entityName: String = 'Currencies';
  submitted: boolean;
  currencyForm: FormGroup;
  currencyDTO: CurrencyDTO = new CurrencyDTO();
  isEdit = false;
  error = '';

  constructor(private activeModal: NgbActiveModal, private currencyService: CurrenciesService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, private utilsService: UtilsService, public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.currencyDTO = this.currencyService.selectedCurrency;
    this.isEdit = this.currencyService.isEdit;

    this.currencyForm = this.formBuilder.group({
      name: [(this.currencyDTO ? this.currencyDTO.name : ''), Validators.required],
      short_code: [(this.currencyDTO ? this.currencyDTO.short_code : ''), Validators.required],
    })
    
  }


  get f() {
    return this.currencyForm.controls;
  }

  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.currencyForm.invalid) {
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): CurrencyDTO {
    var requestDTO = new CurrencyDTO();
    requestDTO.name = this.f.name.value;
    requestDTO.short_code = this.f.short_code.value;
    requestDTO.currency_id = this.currencyDTO.id;
    requestDTO.active = true;
    return requestDTO;
  }

}
