import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { UserDTO } from 'src/app/core/dtos/user.dto';
import { OrganisationsService } from 'src/app/core/services/organisations.service';
import { UserTypesService } from 'src/app/core/services/user-types.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Zimbabwe, CountryISO.SouthAfrica];

  entityName: String = 'Users';
  submitted: boolean;
  userForm: FormGroup;
  userDTO: UserDTO = new UserDTO();
  user_types: any[] = [];
  options: any[] = [];
  isEdit = false;
  error = '';


  constructor(private activeModal: NgbActiveModal, private userService: UserProfileService, private userTypeService: UserTypesService, private toastr: ToastrService, private ngxService: NgxUiLoaderService, private utilsService: UtilsService, public formBuilder: FormBuilder) { 
    this.fetchUserTypes();

    this.options = [
      {
        'value': true,
        'name': "YES",
      },
      {
        'value': false,
        'name': 'NO'
      }
    ]
  }

  ngOnInit(): void {
    this.userDTO = this.userService.selectedUser;
    console.log(this.userDTO);
    this.isEdit = this.userService.isEdit;

    this.userForm = this.formBuilder.group({
      username: [(this.userDTO ? this.userDTO.username : ''), Validators.required],
      first_name: [(this.userDTO ? this.userDTO.first_name : ''), Validators.required],
      last_name: [(this.userDTO ? this.userDTO.last_name : ''), Validators.required],
      email: [(this.userDTO ? this.userDTO.email : ''), [Validators.required, Validators.email]],
      phone_number: [(this.userDTO ? this.userDTO.phone_number : ''), Validators.required],
      user_type_id: [(this.userDTO ? this.userDTO?.user_type?.id : 0), Validators.required],
    })

    this.userForm.patchValue({ user_type_id: this.userDTO?.user_type?.id });

  }

  trackByFn(item: any) {
    return item.id;
  }

  fetchUserTypes() {

    this.ngxService.start();
    this.utilsService.getDataWithoutTable(this.userTypeService.viewActive(), `${this.entityName}`).subscribe(
      data => {
        this.ngxService.stop();
        //set data in service
        this.user_types = data;
        //patch the value of select
        // this.addFaqForm.patchValue({ categoryId: this.selectCategories[0]?.id });
      },
      error => {
        this.ngxService.stop();
      });

  }



  get f() {
    return this.userForm.controls;
  }

  close(){
    this.activeModal.close();
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      console.log(this.userForm)
      return;
    } else {
      //add to list
      //Pass value
      console.log('Passing Value from modal');
      this.activeModal.close(this.createRequestDTO());
    }
  }

  createRequestDTO(): UserDTO {
    var requestDTO = new UserDTO();
    requestDTO.username = this.f.username.value;
    requestDTO.first_name = this.f.first_name.value;
    requestDTO.last_name = this.f.last_name.value;
    requestDTO.email = this.f.email.value;
    requestDTO.phone_number = this.utilsService.getPhonenumberAsString(this.f.phone_number.value);
    requestDTO.user_type_id = this.f.user_type_id.value;
    requestDTO.user_id = this.userDTO.id;
    requestDTO.active = true;
    return requestDTO;
  }


}
