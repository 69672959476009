import { CountryDTO } from "./country.dto";
import { StationDTO } from "./station.dto";

export class ConferenceDTO {

    id: Number;
    name: String;
    address: String;
    station: StationDTO;
    station_id: Number;
    conference_id: Number;
    country: CountryDTO;
    active: Boolean;

}