<form class="needs-validation" (ngSubmit)="submit()" [formGroup]="stationForm">
    <div class="modal-header">
        <h4 class="modal-title">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">New </ng-container>
            Station
        </h4>
        <a class="close" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close noti-icon"></i>
        </a>
    </div>
    <div class="modal-body">
        <div class="row mb-4">
            <div class="form-group col-3 mb-2">
                <label for="validationCustom02">Station Name</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="name" placeholder="Enter Station Name" [ngClass]="{'is-invalid': submitted && f.name.errors,'is-valid': submitted && !f.name.errors}">
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <span *ngIf="f.name.errors?.required">Please provide a valid user name.</span>
                </div>
                <div *ngIf="submitted && !f.name.errors" class="valid-feedback">
                    <span *ngIf="!f.name.errors">Looks good!</span>
                </div>
            </div>
            <div class="form-group col-6 mb-2">
                <label for="validationCustom02">Station Address</label>
                <input type="text" class="form-control" id="validationCustom02" formControlName="address" placeholder="Enter Station Address" [ngClass]="{'is-invalid': submitted && f.address.errors,'is-valid': submitted && !f.address.errors}">
                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                    <span *ngIf="f.address.errors?.required">Please provide a valid <address></address>.</span>
                </div>
                <div *ngIf="submitted && !f.address.errors" class="valid-feedback">
                    <span *ngIf="!f.address.errors">Looks good!</span>
                </div>
            </div>
            <div class="form-group col-3 mb-2">
                <label class="control-label">Country</label>
                <ng-select formControlName="country_id" [items]="countries" bindLabel="name" placeholder="Select Country" bindValue="id" [ngClass]="{'is-invalid': submitted && f.country_id.errors,'is-valid': submitted && !f.country_id.errors}">
                </ng-select>
                <div *ngIf="submitted && f.country_id.errors" class="invalid-feedback">
                    <div *ngIf="f.country_id.errors.required">This value is required</div>
                </div>
            </div>
        </div>
        <h5 *ngIf="!isEdit">Contacts</h5>
        <div class="row mb-4" formArrayName="contacts" *ngIf="!isEdit">
            <div class="form-group mb-2 col-12">
                <button type="button" class="btn btn-primary" (click)="addContact()">
                    <i class="bx bx-plus"></i> Add Contact
                </button>
            </div>

            <div class="col-12 mb-2">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
            </div>
            <ng-container *ngFor="let contact of contacts().controls; let i=index" [formGroupName]="i">
                <div class="row mb-4">
                    <div class="form-group col-4 mb-2">
                        <label for="validationCustom02">Label</label>
                        <input type="text" class="form-control" id="validationCustom02" formControlName="label" placeholder="Enter Label" [ngClass]="{'is-invalid': submitted && contact?.label?.errors,'is-valid': submitted && !contact?.label?.errors}">
                        <div *ngIf="submitted && contact?.label?.errors" class="invalid-feedback">
                            <span *ngIf="contact?.label?.errors?.required">Please provide a valid label.</span>
                        </div>
                        <div *ngIf="submitted && !contact?.label?.errors" class="valid-feedback">
                            <span *ngIf="!contact?.label?.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-4 mb-2">
                        <label for="validationCustom02">Contact First Name</label>
                        <input type="text" class="form-control" id="validationCustom02" formControlName="first_name" placeholder="Enter First Name" [ngClass]="{'is-invalid': submitted && contact?.first_name?.errors,'is-valid': submitted && !contact?.first_name?.errors}">
                        <div *ngIf="submitted && contact?.first_name?.errors" class="invalid-feedback">
                            <span *ngIf="contact?.first_name?.errors?.required">Please provide a valid last name.</span>
                        </div>
                        <div *ngIf="submitted && !contact?.first_name?.errors" class="valid-feedback">
                            <span *ngIf="!contact?.first_name?.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-4 mb-2">
                        <label for="validationCustom02">Contact Last Name</label>
                        <input type="text" class="form-control" id="validationCustom02" formControlName="last_name" placeholder="Enter Last Name" [ngClass]="{'is-invalid': submitted && contact?.last_name?.errors,'is-valid': submitted && !contact?.last_name?.errors}">
                        <div *ngIf="submitted && contact?.last_name?.errors" class="invalid-feedback">
                            <span *ngIf="contact?.last_name?.errors?.required">Please provide a valid last name.</span>
                        </div>
                        <div *ngIf="submitted && !contact?.last_name?.errors" class="valid-feedback">
                            <span *ngIf="!contact?.last_name?.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-4 mb-2">
                        <label for="validationCustom02">Contact Email</label>
                        <input type="text" class="form-control" id="validationCustom02" formControlName="primary_email" placeholder="Enter Email Address" [ngClass]="{'is-invalid': submitted && contact?.primary_email?.errors,'is-valid': submitted && !contact?.primary_email?.errors}">
                        <div *ngIf="submitted && contact?.primary_email?.errors" class="invalid-feedback">
                            <span *ngIf="contact?.primary_email?.errors?.required">Please provide a valid email.</span>
                        </div>
                        <div *ngIf="submitted && !contact?.primary_email?.errors" class="valid-feedback">
                            <span *ngIf="!contact?.primary_email?.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-4 mb-2">
                        <label for="validationCustom02">Contact Phone Number</label>
                        <ngx-intl-tel-input [cssClass]="'form-control form-width'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Zimbabwe" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="true" [ngClass]="{'is-invalid': submitted && contact?.primary_phone.errors,'is-valid': submitted && !contact?.primary_phone.errors}"
                            [numberFormat]="PhoneNumberFormat.National" name="primary_phone" formControlName="primary_phone">
                        </ngx-intl-tel-input>
                        <div *ngIf="submitted && contact?.primary_phone.errors" class="invalid-feedback">
                            <span *ngIf="contact?.primary_phone.errors?.required">Please provide a valid phone number.</span>

                            <span *ngIf="contact?.primary_phone.errors?.validatePhoneNumber">Please provide a valid
                                phone
                                number.</span>
                        </div>
                        <div *ngIf="submitted && !contact?.primary_phone.errors" class="valid-feedback">
                            <span *ngIf="!contact?.primary_phone.errors">Looks good!</span>
                        </div>
                    </div>

                    <div class="form-group col-4 mb-2">
                        <label for="validationCustom02">&nbsp;</label>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-danger" (click)="removeContact(i)">
                                <i class="bx bx-x"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <hr>
            </ng-container>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">
            <ng-container *ngIf="isEdit">Update </ng-container>
            <ng-container *ngIf="!isEdit">Save </ng-container>
            Station
        </button>
        <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
    </div>
</form>