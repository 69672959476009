import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ExpenseDTO } from '../dtos/expense.dto';
import { AppSettings } from '../helpers/app-settings';

@Injectable({
  providedIn: 'root'
})
export class ExpensesService {

  public selectedExpense: ExpenseDTO = new ExpenseDTO();
  public isEdit = false;

  constructor(private http: HttpClient) { }

  viewAll(page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/expenses/?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }


  viewByChurch(page: Number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/expenses/byChurch/?page=${page}&size=${size}`)
    .pipe(map(data => {
      // request successful log response
      console.log(data);
      return data;
    }));
  }

  
  viewActive(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/expenses/active/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewExpenseItems(){

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/expenses/expense/items/`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  viewByStatus(status: boolean, page: number, size: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + `/expenses/view/byStatus/${status}?page=${page}&size=${size}`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));
  }

  changeExpenseStatus(expense_id: string, status: boolean) {

    return this.http.get<any>(AppSettings.API_ENDPOINT + `/expenses/${expense_id}/${status}/changeStatus`)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }

  updateExpense(expense: ExpenseDTO, expense_id: number) {

    return this.http.put<any>(AppSettings.API_ENDPOINT + `/expenses/${expense_id}`, expense)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));


  }


  createExpense(expense: ExpenseDTO){

    return this.http.post<any>(AppSettings.API_ENDPOINT + `/expenses/`, expense)
      .pipe(map(data => {
        // request successful log response
        console.log(data);
        return data;
      }));

  }
}
