import { ContactDTO } from "./contact.dto";
import { KidAttachmentDTO } from "./kid_attachment.dto";
import { StationDTO } from "./station.dto";

export class KidDTO {

    id: Number;
    first_name: String;
    last_name: String;
    alias: String;
    date_of_birth: String;
    circumstances: String;
    date_last_seen: String;
    location_last_seen: String;
    latitude: String;
    longitude: String;
    gender: String;
    height: String;
    weight: String;
    skin: String;
    status: String;
    contact_id: Number;
    station_id: Number;
    contact: ContactDTO;
    station: StationDTO;
    kid_attachments: KidAttachmentDTO[]
    file: File;
    active: Boolean;
    kid_id: Number;

    contact_label: String;
    contact_first_name: String;
    contact_last_name: String;
    contact_primary_phone: String;
    contact_primary_email: String;

}